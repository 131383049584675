<template>
  <section class="user-management">
    <alert-modal v-if="showMessage" />
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">Gestão de Usuários</div>
        <section class="management-containers">
          <div class="left-containers">
            <user-search-container class="search" />
          </div>
          <div class="right-containers">
            <user-create-container />
            <user-screen-permission />
            <div class="save-container">
              <button-component
                text="Salvar"
                class="save-button"
                @click="tryToSave"
              />
            </div>
          </div>
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import UserCreateContainer from "@/components/user-management/user-create-container.vue";
import UserSearchContainer from "@/components/user-management/user-search-container/user-search-container.vue";
import UserScreenPermission from "@/components/user-management/user-screen-permission.vue";
import ButtonComponent from "@/components/template/button-component.vue";
import { mapMutations, mapState } from "vuex";
import UserService from "@/services/UserService";
import AlertModal from "@/components/template/alert-modal.vue";
import AuthService from "@/services/auth/AuthService";

export default {
  components: {
    NavBar,
    DefaultBody,
    UserCreateContainer,
    UserScreenPermission,
    UserSearchContainer,
    ButtonComponent,
    AlertModal,
  },
  data() {
    return {
      showMessage: false,
    };
  },
  computed: {
    ...mapState("user", ["userEditing", "form"]),
  },
  methods: {
    ...mapMutations("user", ["cleanForm"]),

    async tryToSave() {
      const user = {
        email:
          this.userEditing !== null
            ? this.userEditing.email
            : this.form.fields[1].model,

        emailNovo: this.form.fields[1].model,
        nome: this.form.fields[0].model,
        cpf: this.form.fields[2].model,

        senha:
          this.form.fields[3].model === ""
            ? undefined
            : this.form.fields[3].model,

        gestaoUsuarios: this.form.permissions[0].active,
        gestaoConvenios: this.form.permissions[1].active,
        importacaoMassa: this.form.permissions[2].active,
        atualizacaoManual: this.form.permissions[3].active,
        gestaoNotificacoes: this.form.permissions[4].active,
        gestaoErros: this.form.permissions[5].active,
      };

      const response = await UserService.createOrUpdate(
        user,
        this.userEditing === null
      );

      let response2 = true;

      if (this.userEditing === null) {
        response2 = await AuthService.signUp(
          this.userEditing !== null
            ? this.userEditing.email
            : this.form.fields[1].model,
          this.form.fields[3].model === ""
            ? undefined
            : this.form.fields[3].model
        );
      }

      if (response && response2) {
        this.showMessage = true;
        this.cleanForm();
        this.$store.dispatch("user/loadUsers");

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-management {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      > .management-containers {
        display: flex;
        justify-content: flex-start;
        margin-top: 2rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > * {
          width: 50%;
          height: 100%;
        }

        > .left-containers {
          padding-right: 2rem;

          @media (max-width: 800px) {
            padding-right: 0;
            width: 100%;
          }

          > .search {
            margin-top: 2rem;
            height: 100%;

            @media (max-width: 800px) {
              margin-top: 3rem;
            }
          }
        }

        > .right-containers {
          padding-left: 2rem;
          height: 100%;

          @media (max-width: 800px) {
            padding-left: 0;
            width: 100%;
            margin-top: 2rem;
          }

          > .save-container {
            margin-top: 1.5rem;
            width: 100%;
            display: flex;
            justify-content: center;

            > .save-button {
              margin: auto;
              width: 40%;

              @media (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>

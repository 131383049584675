import LoginView from "../views/login-view.vue";
import HomeView from "../views/home-view.vue";
import UserManagement from "../views/user-management.vue";
import CovenantMamagementView from "../views/covenant-management-view.vue";
import UserImportView from "../views/user-import-view.vue";
import UserManualEditView from "../views/user-manual-edit-view.vue";
import Notifications from "../views/notifications-view.vue";
import ErrorManagementView from "../views/error-management-view.vue";
import UserDeletedManagementView from "../views/user-deleted-management-view.vue";
import store from "@/store/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/gestao-de-usuarios",
    name: "Gestão de Usuários",
    component: UserManagement,
    beforeEnter(to, from, next) {
      if (store.state.user.profile.gestaoUsuarios) {
        next();

        return;
      }

      next("/");
    },
  },
  {
    path: "/gestao-de-convenios",
    name: "Gestão de Convênios",
    component: CovenantMamagementView,
    beforeEnter(to, from, next) {
      if (store.state.user.profile.gestaoConvenios) {
        next();

        return;
      }

      next("/");
    },
  },
  {
    path: "/importacao-atualizacao-colaboladores",
    name: "Importação e Atualização em massa dos colaboradores",
    component: UserImportView,
    beforeEnter(to, from, next) {
      if (store.state.user.profile.importacaoMassa) {
        next();

        return;
      }

      next("/");
    },
  },
  {
    path: "/atualizacao-manual-colaboladores",
    name: "Atualização Manual dos Colaboradores",
    component: UserManualEditView,
    beforeEnter(to, from, next) {
      if (store.state.user.profile.atualizacaoManual) {
        next();

        return;
      }

      next("/");
    },
  },
  {
    path: "/notificacoes",
    name: "Notificações",
    component: Notifications,
    beforeEnter(to, from, next) {
      if (store.state.user.profile.gestaoNotificacoes) {
        next();

        return;
      }

      next("/");
    },
  },
  {
    path: "/gestao-de-erros",
    name: "Gerenciamento de Erros",
    component: ErrorManagementView,
    beforeEnter(to, from, next) {
      if (store.state.user.profile.gestaoErros) {
        next();

        return;
      }

      next("/");
    },
  },
  {
    path: "/gestao-de-usuarios-excluidos",
    name: "Gerenciamento de Usuários Excluídos",
    component: UserDeletedManagementView,
    beforeEnter(to, from, next) {
      next();return;

      if (store.state.user.profile.gestaoUsuarios) {
        next();

        return;
      }

      next("/");
    },
  },
];

export default routes;

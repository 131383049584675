import CollaboratorService from "@/services/CollaboratorService";

const userImportState = {
  namespaced: true,
  state: () => ({
    imports: [],
  }),
  mutations: {
    setImports(state, imports) {
      state.imports = imports;
    },
  },
  actions: {
    async loadImports(context) {
      const response = await CollaboratorService.getImport();

      const imports = response.map((item) => {
        const date = new Date(item.data);

        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        
        const month =
          date.getMonth() < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1;

        item.data = `${day}/${month}/${date.getUTCFullYear()}`;

        return item;
      });

      context.commit("setImports", imports);
    },
  },
};

export default userImportState;

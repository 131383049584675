<template>
  <section class="notification-view">
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">
          Notificações
        </div>
        <section class="management-containers">
          <notifications-body />
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import NotificationsBody from '@/components/notifications/notifications-body.vue';

export default {
  components: {
    NavBar,
    DefaultBody,
    NotificationsBody
  }
}
</script>

<style lang="scss">
.notification-view {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      
    }
  }
}
</style>
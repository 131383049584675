const notificationState = {
  namespaced: true,
  state: () => ({
    selectedWay: "Por convênio",
    radios: [
      {
        label: "Por convênio",
      },
      {
        label: "Por CPF individual",
      },
      {
        label: "Todos",
      },
    ],
    covenantsBoolean: [],
    cpf: "",
    title: "Zimpe App",
    body: "",
    file: null,
  }),
  mutations: {
    cleanForm(state) {
      state.covenantsBoolean = state.covenantsBoolean.map(() => false);
      state.file = null;
      state.body = "";
    },

    setCovenantsBooleans(state, values) {
      state.covenantsBoolean = values;
    },

    setSelectedWay(state, way) {
      state.selectedWay = way;
    },

    setCPF(state, cpf) {
      state.cpf = cpf.value;
    },

    setFile(state, file) {
      state.file = file;
    },

    setBody(state, body) {
      state.body = body.target.value;
    },
  },
  actions: {
    async sendNotification(context) {},
  },
};

export default notificationState;

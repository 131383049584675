<template>
  <section class="notifications">
    <alert-modal v-if="showMessage" />
    <div class="top-button">
      <button-component text="Envio de Notificações" class="button" />
    </div>
    <section class="form">
      <div class="fields">
        <textarea
          class="notification-field"
          placeholder="Digite o texto da notificação"
          v-model="body"
          @change="setBody"
        >
        </textarea>
        <div class="input-field">
          <span class="text">{{ fileName }}</span>
          <button-component
            text="Selecionar arquivo"
            class="button"
            @click="pickFile"
          />
          <input type="file" hidden ref="file" @change="uploadFile" />
        </div>
      </div>
      <notification-selector class="selector" />
    </section>
    <section class="send">
      <button-component text="Enviar" class="send-button" @click="send" />
    </section>
  </section>
</template>

<script>
import ButtonComponent from "../template/button-component.vue";
import NotificationSelector from "./notification-selector.vue";
import NotificationService from "@/services/NotificationService";
import { mapMutations, mapState } from "vuex";
import AlertModal from "@/components/template/alert-modal.vue";
import AWSS3UploadAshClient from "aws-s3-upload-ash";

export default {
  components: {
    ButtonComponent,
    NotificationSelector,
    AlertModal,
  },
  data() {
    return {
      fileName: "Selecione o arquivo, apenas imagens PNG",
      showMessage: false,
      aws: {
        bucketName: process.env.VUE_APP_AWS_BUCKET_NAME,
        region: process.env.VUE_APP_AWS_REGION,
        accessKeyId: process.env.VUE_APP_AWS_KEY,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET,
        s3Url: process.env.VUE_APP_AWS_BUCKET_URL,
      },
    };
  },
  computed: {
    ...mapState("notification", [
      "selectedWay",
      "cpf",
      "file",
      "body",
      "title",
      "covenantsBoolean",
    ]),

    ...mapState("covenant", ["covenants"]),
  },
  methods: {
    ...mapMutations("notification", ["setFile", "setBody", "cleanForm"]),

    pickFile() {
      this.$refs.file.click();
    },

    async uploadS3() {
      let S3CustomClient = new AWSS3UploadAshClient(this.aws);

      try {
        await S3CustomClient.uploadFile(
          this.file,
          this.file.type,
          undefined,
          "notificacao.png",
          undefined
        );
      } catch (e) {
        console.log(e);
      }
    },

    async send() {
      let response = null;

      await this.uploadS3();

      if (this.selectedWay === "Por convênio") {
        let total = [];

        for (let i in this.covenantsBoolean) {
          if (!this.covenantsBoolean[parseInt(i)]) continue;

          total.push(
            await NotificationService.sendNotification({
              cnpj: this.covenants[parseInt(i)].cnpj,
              allEmployee: false,
              title: this.title,
              body: this.body,

              image:
                this.file !== null
                  ? "https://elasticbeanstalk-sa-east-1-493808739571.s3.sa-east-1.amazonaws.com/notificacao.png"
                  : undefined,

              data: {
                key1: "",
                key2: "",
              },
            })
          );
        }

        response = total.every((item) => item);
      } else {
        response = await NotificationService.sendNotification({
          cpf:
            this.selectedWay === "Por CPF individual"
              ? this.cpf.replace(/\./g, "").replace(/\-/g, "")
              : undefined,

          cnpj: this.selectedWay === "Por convênio" ? "cnpj" : undefined,
          allEmployee: this.selectedWay === "Todos",
          title: this.title,
          body: this.body,

          image:
            this.file !== null
              ? "https://elasticbeanstalk-sa-east-1-493808739571.s3.sa-east-1.amazonaws.com/notificacao.png"
              : undefined,

          data: {
            key1: "",
            key2: "",
          },
        });
      }

      if (response) {
        this.fileName = "";
        this.showMessage = true;
        this.cleanForm();

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },

    uploadFile(evt) {
      this.setFile(evt.target.files[0]);
      this.fileName = this.file.name;
    },
  },
};
</script>

<style lang="scss">
.notifications {
  > .top-button {
    margin-top: 3rem;

    > .button {
      width: 15%;

      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }

  > .send {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;

    > .send-button {
      width: 30%;
    }
  }

  > .form {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 2rem;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      width: 100%;
    }

    > .fields {
      width: 40%;

      @media (max-width: 800px) {
        width: 100%;
      }

      > .notification-field {
        padding: 2rem;
        background-color: $lightgrey;
        border-radius: 15px;
        height: 15vh;
        border: none;
        width: 100%;
        color: black;

        @media (max-width: 800px) {
          padding: 0;
        }
      }

      > .input-field {
        border: 1px dashed black;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 15px;
        margin-top: 1rem;
        width: 100%;
        cursor: pointer;

        @media (max-width: 800px) {
          padding: 2rem 0;
        }

        > .text {
          width: 100%;
          text-align: center;
        }

        > .button {
          margin-top: 1rem;
          width: 40%;
          background-color: #c3eac7;
          color: black;

          @media (max-width: 800px) {
            width: 80%;
          }
        }
      }
    }

    > .selector {
      width: 45%;

      @media (max-width: 800px) {
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
</style>

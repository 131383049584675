import { createStore } from "vuex";
import collaboratorsState from "./collaborators";
import covenantState from "./covenant";
import errorState from "./error";
import notificationState from "./notification";
import userState from "./user";
import userImportState from "./user-import";
import deleteUserState from "./deleted-users";

const store = createStore({
  modules: {
    collaborator: collaboratorsState,
    covenant: covenantState,
    'deleted-user': deleteUserState,
    error: errorState,
    notification: notificationState,
    user: userState,
    'user-import': userImportState,
  },
});

export default store;

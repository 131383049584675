export const regularIcons = [
  'faUser',
  'faUserCircle'
];

export const solidIcons = [
  'faLock',
  'faHouse',
  'faMagnifyingGlass',
  'faBars',
  'faXmark',
];
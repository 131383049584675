import UserService from "@/services/UserService";

const userState = {
  namespaced: true,
  state: () => ({
    profile: {},
    users: {
      content: [],
      totalPages: 0,
    },
    userEditing: null,
    form: {
      fields: [
        { label: "Nome", mask: [], id: "name", model: "", type: "input" },
        { label: "E-mail", mask: [], id: "email", model: "", type: "input" },
        { label: "CPF", mask: ["###.###.###-##"], id: "cpf", model: "", type: "input" },
        { label: "Senha", mask: [], id: "senha", model: "", type: "password" },
      ],
      permissions: [
        {
          name: "Gestão de usuários",
          active: false,
        },
        {
          name: "Gestão de convênios",
          active: false,
        },
        {
          name: "Importação em massa",
          active: false,
        },
        {
          name: "Atualização manual",
          active: false,
        },
        {
          name: "Gestão de notificações",
          active: false,
        },
        {
          name: "Gestão de erros",
          active: false,
        },
      ],
    },
  }),
  mutations: {
    cleanForm(state) {
      state.userEditing = null;
      state.form.fields[0].model = "";
      state.form.fields[1].model = "";
      state.form.fields[2].model = "";

      state.form.permissions = state.form.permissions.map((permission) => {
        permission.active = false;

        return permission;
      });
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setUsers(state, users) {
      state.users = users;
    },
    setUserEditing(state, user) {
      state.userEditing = user;
      state.form.fields[0].model = user.nome;
      state.form.fields[1].model = user.email;
      state.form.fields[2].model = user.cpf
        .replace(/\./g, "")
        .replace(/\-/g, "");

      state.form.permissions[0].active = user.gestaoUsuarios;
      state.form.permissions[1].active = user.gestaoConvenios;
      state.form.permissions[2].active = user.importacaoMassa;
      state.form.permissions[3].active = user.atualizacaoManual;
      state.form.permissions[4].active = user.gestaoNotificacoes;
      state.form.permissions[5].active = user.gestaoErros;
    },
  },
  actions: {
    async loadProfile(context, email) {
      const response = await UserService.getInfo(email);
      context.commit("setProfile", response);
    },
    async loadUserEditing(context, user) {
      context.commit("setUserEditing", user);
    },
    async loadUsers(context, data = { query: "", page: 0 }) {
      const response = await UserService.getUsers(data.query, data.page);

      if (response !== null) {
        context.commit("setUsers", {
          content: response,
          totalPages: 1,
        });

        return;
      }

      context.commit("setUsers", {
        content: [
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-90",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-91",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-92",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-93",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
          {
            nomeCompleto: "Paulo Souza",
            email: "paulocsf@gmail.com",
            cpf: "098.192.332-94",
          },
        ],
        totalPages: 10,
      });
    },
  },
};

export default userState;

<template>
  <section class="covenant-data-form">
    <div
      v-for="(item, index) in form.covenantFields"
      :key="`${index}-up`"
      class="field"
    >
      <input-component
        template="semi-transparent"
        :type="item.type"
        :mask="item.mask"
        v-model="item.model"
        :label="item.label"
        class="input"
        :errorMessage="covenantFieldsErrorMessage(index)"
        :errorCondition="covenantFieldsErrorCondition(index)"
      />
    </div>
    <section class="contact-form">
      <div class="subtitle">Contato do Convênio</div>
      <div
        v-for="(item, index) in form.contactFields"
        :key="`${index}-medium`"
        class="field"
      >
        <input-component
          template="semi-transparent"
          :type="item.type"
          :mask="item.mask"
          v-model="item.model"
          :label="item.label"
          class="input"
          :errorMessage="contactFieldsErrorMessage(index)"
          :errorCondition="contactFieldsErrorCondition(index)"
        />
      </div>
      <div class="field">
        <input-component
          template="semi-transparent"
          :type="form.bottomFields[0].type"
          :mask="form.bottomFields[0].mask"
          v-model="form.bottomFields[0].model"
          :label="form.bottomFields[0].label"
          :labelWidth="70"
          class="input"
          :errorCondition="form.bottomFields[0].model < 1"
        />
      </div>
      <div class="field --wrap">
        <input-component
          template="semi-transparent"
          :type="form.bottomFields[1].type"
          :mask="form.bottomFields[1].mask"
          v-model="form.bottomFields[1].model"
          :label="form.bottomFields[1].label"
          class="input --expanded"
          :errorCondition="bottomFieldsErrorCondition(1)"
        />
      </div>
    </section>
  </section>
</template>

<script>
import InputComponent from "@/components/template/input-component.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    InputComponent,
  },
  computed: {
    ...mapState("covenant", ["form"]),
  },
  methods: {
    ...mapMutations("covenant", ["setFormItemValidation"]),

    bottomFieldsErrorCondition(index) {
      const isInvalid =
        this.form.bottomFields[index].model.length > 0 &&
        this.form.bottomFields[index].model.length < 10;

      this.setFormItemValidation({
        fieldPosition: "bottomFields",
        index,
        value: !isInvalid,
      });

      return isInvalid;
    },

    contactFieldsErrorCondition(index) {
      const specialError =
        this.form.contactFields[index].model.length > 0 &&
        this.form.contactFields[index].model.length < 16;

      const defaultError =
        this.form.contactFields[index].model.length > 0 &&
        this.form.contactFields[index].model.length < 3;

      const isInvalid = index === 1 ? specialError : defaultError;

      this.setFormItemValidation({
        fieldPosition: "contactFields",
        index,
        value: !isInvalid,
      });

      return isInvalid;
    },

    contactFieldsErrorMessage(index) {
      return index === 1
        ? "Telefone invalido."
        : "Preencha o campo corretamente.";
    },

    covenantFieldsErrorCondition(index) {
      const specialError =
        this.form.covenantFields[index].model.length > 0 &&
        this.form.covenantFields[index].model.length < 18;

      const defaultError =
        this.form.covenantFields[index].model.length > 0 &&
        this.form.covenantFields[index].model.length < 3;

      const isInvalid = index === 0 ? specialError : defaultError;

      this.setFormItemValidation({
        fieldPosition: "covenantFields",
        index,
        value: !isInvalid,
      });

      return isInvalid;
    },

    covenantFieldsErrorMessage(index) {
      return index === 0 ? "CNPJ invalido." : "Preencha o campo corretamente.";
    },
  },
};
</script>

<style lang="scss" scoped>
.covenant-data-form {
  background-color: $lightgrey;
  height: 100%;
  border-radius: 15px;
  padding: 1rem;

  > .contact-form {
    margin-top: 2rem;

    > .subtitle {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }

  .field {
    display: flex;
    width: 100%;
    align-items: flex-end;
    font-size: 12px;

    &.--wrap {
      flex-wrap: wrap;
    }

    > .label {
      width: 25%;

      &.--semi-expanded {
        width: 40%;
      }

      &.--expanded {
        width: 100%;

        @media (max-width: 800px) {
          width: 50%;
        }
      }
    }

    > .input {
      width: 100%;

      &.--expanded {
      }
    }
  }
}
</style>

<template>
  <div class="group-field" :class="[`--${template}`, {'--error': errorCondition}]">
    <section class="input-container">
      <template v-if="icon.length > 0">
        <img
          :src="`${icon}`"
          alt="Input image"
          v-if="isImage"
          class="icon"
          :class="{ '--revert': !iconBegin }"
          :style="{ height: iconHeight + 'px', width: iconWidth + 'px' }"
        />
        <font-awesome-icon
          :icon="icon"
          class="icon"
          :class="{ '--revert': !iconBegin }"
          v-else
        />
      </template>
      <label :for="label" v-if="label.length > 0" class="label" :class="{'--fully': type === 'file'}">
        <template v-if="type !== 'file'">{{label}}:</template>
        <template v-else>{{label}}</template>
      </label>
      <input
        :type="type"
        class="input"
        :class="{ '--icon': icon.length > 0, '--revert': !iconBegin }"
        :placeholder="placeholder"
        :name="name"
        v-model="fieldValue"
        @input="onChange"
        v-if="mask.length === 0"
        :id="label"
        @change="reactOnChange"
        ref="input"
      />
      <input
        :type="type"
        class="input"
        :class="{ '--icon': icon.length > 0, '--revert': !iconBegin }"
        :placeholder="placeholder"
        :name="name"
        v-model="fieldValue"
        @input="onChange"
        v-mask="mask"
        :id="label"
        @change="reactOnChange"
        ref="input"
        v-else
      />
    </section>
    <span class="error" v-if="errorCondition">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: String,
    placeholder: String,
    icon: {
      default: "",
      type: String,
    },
    name: String,
    iconBegin: {
      default: true,
      type: Boolean,
    },
    isImage: {
      default: false,
      type: Boolean,
    },
    iconWidth: {
      type: Number,
      default: 20,
    },
    iconHeight: {
      type: Number,
      default: 20,
    },
    type: {
      default: "text",
      type: String,
    },
    mask: {
      default: () => [],
      type: Array,
    },
    template: {
      default: "default",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    labelWidth: {
      default: 50,
      type: Number
    },
    errorMessage: {
      default: 'Preencha corretamente o campo.',
      type: String
    },
    errorCondition: {
      default: false,
      type: Boolean
    }
  },
  emits: ["update:modelValue", 'change'],
  data() {
    return {
      fieldValue: "",
    };
  },
  mounted() {
    this.fieldValue = this.modelValue;
  },
  computed: {
    labelWidthPercentage() {
      return `${this.labelWidth}%`;
    }
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.fieldValue);
    },
    reactOnChange(evt) {
      this.$emit('change', this.$refs.input)
    }
  },
  watch: {
    modelValue(value) {
      this.fieldValue = value;
    }
  }
};
</script>

<style lang="scss">
.group-field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  margin: 10px 0;
  flex-wrap: wrap;

  &.--error {
    > .input-container {
      > .input {
        border-color: red !important;
      }

      > .label {
        border-color: red !important;
      }
    }
  }

  &.--semi-transparent {
    > .input-container {
      > .input {
        border: 2px solid black;
        border-left: none;
        border-right: none;
        border-top: none;
        background-color: transparent;
        padding: 0;
      }

      input[type="file"] {
        display: none;
      }

      > .label {
        border: 2px solid black;
        border-left: none;
        border-right: none;
        border-top: none;
        background-color: transparent;
      }
    }
  }

  &.--transparent {
    > .input-container {
      > .input {
        border: none;
        background-color: transparent;
      }
    }
  }

  > .error {
    width: 100%;
    color: red;
    text-align: center;
    margin-top: 5px;
  }

  > .input-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    width: 100%;

    > .icon {
      position: absolute;
      padding: 10px 5px;
      min-width: 20px;
      color: $blue;
  
      &.--revert {
        right: 0;
      }
    }
  
    > img.icon {
      width: 20px;
      padding-top: 5px;
    }
  
    > .label {
      border: 2px solid $lightblue;
      border-left: none;
      border-right: none;
      border-top: none;
      width: v-bind(labelWidthPercentage);

      &.--fully {
        width: 100%;
      }
    }
  
    > .input {
      border: 2px solid $lightblue;
      border-left: none;
      border-right: none;
      border-top: none;
      padding: 0.5rem;
      color: $darkblue;
      font-size: 14px;
      width: 100%;
  
      &.--icon {
        padding-left: 40px;
  
        &.--revert {
          padding-left: 0;
          padding-right: 40px;
        }
      }
  
      &:focus {
        outline: none;
      }
    }
  }

  ::placeholder {
    color: $darkblue;
    opacity: 1;
  }
}
</style>

<template>
  <section class="covenant-search">
    <div class="header">
      <button-component
        class="button"
        text="Pesquisar cpf"
        @click="tryToSearch"
      />
      <div class="input-container">
        <input-component
          template="transparent"
          class="search-input"
          :isImage="false"
          :icon="['fas', 'magnifying-glass']"
          :iconBegin="false"
          v-model="search"
        />
      </div>
    </div>
    <div class="body">
      <deleted-user-search-list
        :users="deletedUsers.content"
        @showViewer="setShowViewer"
      />
    </div>
    <div class="footer">
      <template v-if="page > 4">
        <span class="page-list" @click="changePage(1)">
          {{ 1 }}
        </span>
        ...
      </template>
      <span
        class="page-list"
        v-for="i in maxPageList"
        :key="i"
        :class="{ '--active': i === page }"
        @click="changePage(i)"
      >
        {{ i }}
      </span>
      <template v-if="breakPage !== null">
        ...
        <span class="page-list" @click="changePage(breakPage)">
          {{ breakPage }}
        </span>
      </template>
    </div>
    <line-viewer
      v-if="showViewer"
      :text="viewerData"
      @close="showViewer = false"
    />
  </section>
</template>

<script>
import ButtonComponent from "../../template/button-component.vue";
import InputComponent from "../../template/input-component.vue";
import { mapState } from "vuex";
import DeletedUserSearchList from "./deleted-user-search-list.vue";
import LineViewer from "@/components/line-viewer.vue";

export default {
  components: {
    ButtonComponent,
    InputComponent,
    DeletedUserSearchList,
    LineViewer,
  },
  data() {
    return {
      maxPageList: [],
      breakPage: null,
      page: 1,
      search: "",
      showViewer: false,
      viewerData: null,
    };
  },
  mounted() {
    this.fetchDeletedUsers();
    this.relogicPages(this.totalPages);
  },
  computed: {
    ...mapState("deleted-user", ["deletedUsers"]),

    totalPages() {
      return this.deletedUsers.totalPages;
    },
  },
  methods: {
    async fetchDeletedUsers() {
      await this.$store.dispatch("deleted-user/loadDeletedUsers");
    },

    async tryToSearch() {
      await this.$store.dispatch("deleted-user/loadDeletedUsers", {
        cpf: this.search,
        page: this.page - 1,
      });
    },

    setShowViewer(data) {
      this.showViewer = true;

      const keys = {
        nomeCompleto: "Nome",
        cpf: "CPF",
        email: "E-mail",
        cargo: "Cargo",
        celular: "Celular",
        banco: "Banco",
        cep: "Cep",
        ddd: "DDD",
        dddcelular: "DDD celular",
        telefone: "Telefone",
        dataNascimento: "Data de nascimento",
        endereco: "Endereço",
        estado: "Estado",
        salario: "Salário",
        rendaMensal: "Renda mensal",
        cetmes: "Taxa Cet por mês",
        cnpjEmpregador: "CNPJ do empregador",
        cnpjempresa: "CNPJ da empresa",
        escolaridade: "Escolaridade",
        dataAdmissao: "Data admissão",
        importViaAdmin: "Importado via admin",
        matricula: "Matrícula",
        margemParcial: "Margem parcial",
        margemTotal: "Margem total",
        source: "Origem",
        status_proposta: "Status da proposta - BV",
        numero_proposta: "Número da proposta - NB",
        telefoneBv: "Telefone cadastrado - BV",
        indicadorrestricaocredito: "Indicador de restrição de crédito - BV",
        dadospreaprovado_codigo: "Código de dados pre-aprovados - BV",
        dadospreaprovado_percentualminimovalorentrada:
          "Porcentual minimo de valor de entrada (Dados pré-aprovados)",
        dadospreaprovado_pmtmaximo: "Pmt máximo (Dados pré-aprovados) - BV",
        dadospreaprovado_prazomaximofinanciamento:
          "Prazo máximo financiamento (Dados pré-aprovados) - BV",
        dadospreaprovado_valormaximofinanciamento:
          "Valor máximo financiamento (Dados pré-aprovados) - BV",
        situacaopessoacredito_codigo: "Código de situação pessoa crédito - BV",
        situacaopessoacredito_desc: "Descrição de situação pessoa crédito - BV",
        statusproposta: "Status da proposta - Neon",
        numeroproposta: "Número proposta - Neon",
        numeroparcelas: "Número de parcelas - Neon",
        valorparcela: "Valor de parcela - Neon",
        valorproposta: "Valor da proposta - Neon",
        taxames: "Taxa mês - Neon",
        descontosEmprestimo: "Desconto de empréstimos - Neon",
        id_tipo_credito: "Id do tipo de crédito - Neon",
        idsimulacao: "Id da simulação - Neon",
        cod_banco: "Código banco - Neon",
        codigosimulacao: "Código simulação - Neon",
      };

      let text = "";

      Object.keys(keys).forEach((item) => {
        text += `<div style="margin-bottom: 10px">${keys[item]}: ${data[item]}</div>`;
      });

      this.viewerData = text;
    },
    changePage(index) {
      this.page = index;

      this.$store.dispatch("deleted-user/loadDeletedUsers", {
        cpf: this.search,
        page: this.page - 1,
      });

      if (index === 1) {
        this.relogicPages(this.totalPages);
      }

      if (index === this.totalPages) {
        this.relogicPages(this.totalPages, index - 4);

        return;
      }

      if (index === this.maxPageList.slice(-1)[0]) {
        this.relogicPages(this.totalPages, index - 1);

        return;
      }

      if (index === this.maxPageList[0]) {
        const count = index - 4 > 0 ? index - 4 : 0;

        this.relogicPages(this.totalPages, count);
      }
    },
    relogicPages(maxValue, minValue = 0) {
      const arr = [];

      for (let i = minValue; i < maxValue; i++) {
        if (i < 0) {
          continue;
        }

        arr.push(i + 1);

        if (i === minValue + 4) {
          break;
        }
      }

      if (maxValue > minValue + 4) {
        this.breakPage = maxValue;
      } else {
        this.breakPage = null;
      }

      this.maxPageList = arr;
    },
  },
  watch: {
    totalPages(value) {
      this.relogicPages(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.covenant-search {
  width: 50%;

  > .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }

    > .button {
      width: 20%;
      font-size: 12px;

      @media (max-width: 800px) {
        height: 30px;
        width: 95%;
        margin-top: 1rem;
      }
    }

    > .input-container {
      width: 70%;
      background-color: $lightgrey;
      border-radius: 15px;
      padding: 0 1rem;

      @media (max-width: 800px) {
        width: 85%;
      }

      > .search-input {
        width: 100%;
      }
    }
  }

  > .body {
    margin-top: 1.5rem;
    width: 100%;
    height: 45vh;
    overflow: auto;
  }

  > .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 5px;

    > .page-list {
      border: 1px solid grey;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 0 3px;
      cursor: pointer;

      &.--active {
        background-color: $blue;
        color: white;
      }
    }
  }
}
</style>

import BaseService from "./BaseService";

const BankService = {
  getBanks: async () => {
    const response = await BaseService.get(
      "/managementCompany/api/zimpe-management-company/v1/list-banks",
      {
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null ? response.data : [];
  },
};

export default BankService;

export default [
  {
    image: '/assets/icons/ICONES-01.svg',
    text: 'Gestão de Usuários',
    path: '/gestao-de-usuarios',
    key: 'gestaoUsuarios'
  },
  {
    image: '/assets/icons/ICONES-03.svg',
    text: 'Gestão de Convênios',
    path: '/gestao-de-convenios',
    key: 'gestaoConvenios'
  },
  {
    image: '/assets/icons/ICONES-05.svg',
    text: 'Importação e Atualização em massa dos colaboradores',
    path: '/importacao-atualizacao-colaboladores',
    key: 'importacaoMassa'
  },
  {
    image: '/assets/icons/ICONES-02.svg',
    text: 'Atualização manual dos Colaboradores',
    path: '/atualizacao-manual-colaboladores',
    key: 'atualizacaoManual'
  },
  {
    image: '/assets/icons/ICONES-02.svg',
    text: 'Gerenciamento de Colaboradores Excluídos',
    path: '/gestao-de-usuarios-excluidos',
    key: 'atualizacaoManual'
  },
  {
    image: '/assets/icons/ICONES-04.svg',
    text: 'Notificações',
    path: '/notificacoes',
    key: 'gestaoNotificacoes'
  },
  {
    image: '/assets/icons/ICONES-06.svg',
    text: 'Gerenciamento de erros',
    path: '/gestao-de-erros',
    key: 'gestaoErros'
  },
]
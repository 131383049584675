<template>
  <section class="default-body">
    <default-body-header class="header"/>
    <slot />
  </section>
</template>

<script>
import DefaultBodyHeader from './default-body-header/default-body-header.vue'

export default {
  components: {
    DefaultBodyHeader
  }
}
</script>

<style lang="scss" scoped>
  .default-body {
    background-color: $lightgrey;
    width: 100%;
    min-height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 0 4rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: 800px) {
      padding: 0 1rem;
      width: 80%;
    }

    > .header {
      height: 25px;
      width: 99%;
      margin: 0 auto;
      padding: 2rem 0;
    }
  }
</style>
<template>
  <form class="form" @submit.prevent="() => {}">
    <alert-modal v-if="showMessage" text="Senha alterada com sucesso !" />
    <input-component
      icon="/assets/icons/user-icon.png"
      placeholder="Insira seu e-mail"
      name="email"
      class="input"
      v-model="email"
      :isImage="true"
      v-if="!posEmail && !posToken"
      errorMessage="Não foi possível enviar e-mail recuperação"
      :errorCondition="emailError"
    />
    <input-component
      placeholder="Insira o token enviado ao seu email"
      name="Token"
      class="input"
      v-model="token"
      v-if="posEmail && !posToken"
      errorMessage="Token invalido"
      :errorCondition="tokenError"
    />
    <input-component
      placeholder="Digite sua nova senha"
      name="Password"
      class="input"
      v-model="password"
      type="password"
      v-if="posToken"
      errorMessage="Não foi possível alterar a senha"
      :errorCondition="passError"
    />
    <span class="email-sent" v-show="emailSent">E-mail enviado !</span>
    <button-component
      class="button"
      text="Enviar e-mail"
      @click="onSubmit"
      :borderRadius="8"
      v-if="!posEmail && !posToken"
    />
    <button-component
      class="button"
      text="Validar token"
      @click="validateToken"
      :borderRadius="8"
      v-if="posEmail && !posToken"
    />
    <button-component
      class="button"
      text="Cadastrar nova senha"
      @click="sendNewPassword"
      :borderRadius="8"
      v-if="posToken"
    />
    <span class="forgot-password" @click="$emit('goToLogin')"
      >Voltar para login</span
    >
  </form>
</template>

<script>
import AuthService from "@/services/auth/AuthService";
import ButtonComponent from "../template/button-component.vue";
import InputComponent from "../template/input-component.vue";
import AlertModal from "@/components/template/alert-modal.vue";

export default {
  components: {
    AlertModal,
    InputComponent,
    ButtonComponent,
  },
  emits: ['reset'],
  data() {
    return {
      email: "",
      emailError: false,
      emailSent: false,
      posEmail: false,
      token: "",
      tokenError: false,
      password: "",
      passError: false,
      showMessage: false,
      posToken: false,
    };
  },
  methods: {
    async onSubmit() {
      const response = await AuthService.forgotPassword(this.email);

      if (response) {
        this.emailSent = true;

        setTimeout(() => {
          this.emailSent = false;
          this.posEmail = true;
        }, 1500);

        return;
      }

      this.emailError = true;
    },

    async validateToken() {
      const response = await AuthService.validateToken({
        email: this.email,
        tokenEmail: this.token,
      });

      if (response) {
        this.emailSent = false;
        this.tokenError = false;
        this.posToken = true;

        return;
      }

      this.tokenError = true;
    },

    async sendNewPassword() {
      const response = await AuthService.changePassword({
        email: this.email,
        senha: this.password,
      });

      if (response) {
        this.showMessage = true;
        this.email = "";
        this.emailSent = false;
        this.posEmail = false;
        this.token = "";
        this.tokenError = false;
        this.password = "";
        this.passError = false;
        this.emailError = false;
        this.$emit('reset');

        setTimeout(() => {
          this.showMessage = false;
        }, 1500);

        return;
      }

      this.passError = true;
    },
  },
};
</script>

<style lang="scss">
.form {
  > .input {
    margin-bottom: 2rem;
    width: 60%;
    display: block;

    @media (max-width: 800px) {
      width: 80%;
    }
  }

  > .email-sent {
    width: 100%;
    color: $blue;
  }

  > .button {
    width: 41%;
    margin-top: 4rem;

    @media (max-width: 800px) {
      width: 60%;
      margin: auto;
    }
  }

  .forgot-password {
    width: 100%;
    margin-top: 3rem;
    color: $blue;
    font-weight: 500;
    cursor: pointer;
  }
}
</style>

<template>
  <section class="covenant-management">
    <alert-modal v-if="showMessage" />
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">Gestão de Convênios</div>
        <div class="top-button">
          <button-component text="Cadastrar convênio" class="button" @click="cleanForm"/>
        </div>
        <section class="management-containers">
          <div class="left-containers">
            <section class="forms">
              <div class="covenant-forms-container">
                <covenant-forms />
              </div>
              <div class="covenant-app">
                <covenant-app-possibility />
                <covenant-service-control />
              </div>
            </section>
            <div class="save-button">
              <div class="error">
                {{ error }}
              </div>
              <button-component
                text="Salvar"
                class="button"
                @click="tryToSave"
                :disabled="!isFormEnabled"
              />
            </div>
          </div>
          <div class="right-containers">
            <convenant-search class="search" />
          </div>
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import CovenantForms from "@/components/covenant-management/covenant-forms/covenant-forms.vue";
import CovenantAppPossibility from "@/components/covenant-management/covenant-app-possibility.vue";
import ButtonComponent from "@/components/template/button-component.vue";
import CovenantServiceControl from "@/components/covenant-management/covenant-service-control.vue";
import ConvenantSearch from "@/components/covenant-management/covenant-search/convenant-search.vue";
import CovenantService from "@/services/CovenantService";
import { mapActions, mapMutations, mapState } from "vuex";
import AlertModal from "@/components/template/alert-modal.vue";

export default {
  components: {
    NavBar,
    DefaultBody,
    CovenantForms,
    CovenantAppPossibility,
    ButtonComponent,
    CovenantServiceControl,
    ConvenantSearch,
    AlertModal,
  },
  data() {
    return {
      error: "",
      showMessage: false,
    };
  },
  mounted() {
    this.loadServices();
  },
  computed: {
    ...mapState("covenant", ["form", 'editingCovenant']),

    isFormEnabled() {
      return ["covenantFields", "contactFields", "bottomFields"]
        .map((item) => {
          return this.form[item].every(
            (field) =>
              (field.valid && field.model.length > 0) ||
              field.label === "Quantidade de colaboradores"
          );
        })
        .every((valid) => valid);
    },
  },
  methods: {
    ...mapActions("covenant", ["loadServices", 'loadCovenants']),
    ...mapMutations('covenant', ['cleanForm']),

    async tryToSave() {
      const isEditing = this.editingCovenant != null;

      const fgts = this.form.services.fgts.filter(bank => bank.active).map(bank => {
        return {
          name: bank.name,
          tipoCredito: 'FGTS'
        }
      });

      const privateCredit = this.form.services.privateCredit.filter(bank => bank.active).map(bank => {
        return {
          name: bank.name,
          tipoCredito: 'privateCredit'
        }
      })

      const response = await CovenantService.createCovenant({
        cnpj: this.form.covenantFields[0].model,
        razaoSocial: this.form.covenantFields[1].model,
        nomeFantasia: this.form.covenantFields[2].model,
        nome: this.form.contactFields[0].model,
        telefone: this.form.contactFields[1].model,
        email: this.form.contactFields[2].model,
        quantidadeColaboradores: this.form.bottomFields[0].model,
        endereco: this.form.bottomFields[1].model,
        bancos: [...fgts, ...privateCredit],
        propostaSemBase: this.form.appPossibility === "Sim",
      }, isEditing);

      if (response) {
        this.showMessage = true;
        this.cleanForm();
        this.loadCovenants();

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss">
.covenant-management {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .top-button {
        width: 30%;
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;

        > .button {
          width: 50%;
        }

        @media (max-width: 800px) {
          width: 100%;

          > .button {
            width: 100%;
          }
        }
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      > .management-containers {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > * {
          height: 100%;
        }

        > .left-containers {
          display: flex;
          justify-content: center;
          width: 55%;
          padding-right: 2rem;
          flex-wrap: wrap;

          @media (max-width: 800px) {
            width: 100%;
            padding-right: 0;
          }

          > .forms {
            display: flex;
            height: 100%;

            @media (max-width: 800px) {
              flex-wrap: wrap;
            }

            > .covenant-forms-container {
              width: 55%;
              height: 100%;
              display: flex;

              @media (max-width: 800px) {
                width: 100%;
              }
            }

            > .covenant-app {
              margin-left: 1rem;
              width: 45%;
              display: flex;
              flex-direction: column;

              @media (max-width: 800px) {
                margin-left: 0;
                margin-top: 3rem;
                width: 100%;
              }
            }
          }

          > .save-button {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1rem;
            text-align: center;

            > .button {
              width: 15%;
              height: 30px;
              margin-top: 2rem;

              @media (max-width: 800px) {
                width: 60%;
              }
            }

            > .error {
              width: 100%;
              color: red;
              margin-bottom: 1rem;
            }
          }
        }

        > .right-containers {
          width: 45%;
          padding-left: 2rem;

          @media (max-width: 800px) {
            padding-left: 0;
            width: 100%;
            margin-top: 2rem;
          }

          > .search {
            width: 100%;
            height: 55vh;
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>

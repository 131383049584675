<template>
  <form class="form" @submit.prevent="onSubmit">
    <input-component
      icon="/assets/icons/user-icon.png"
      placeholder="Insira seu e-mail"
      name="email"
      class="input"
      v-model="email"
      :isImage="true"
    />
    <input-component
      icon="/assets/icons/password-icon.png"
      placeholder="Insira sua senha"
      name="senha"
      class="input"
      type="password"
      v-model="password"
      :isImage="true"
      :iconHeight="25"
    />
    <span class="login-error" v-if="error">
      Usuário ou senha invalido(s).
    </span>
    <button-component
      class="button"
      text="Entrar"
      @click="onSubmit"
      :borderRadius="8"
    />
    <span class="forgot-password" @click="$emit('forgotPassword')">Esqueceu sua senha?</span>
  </form>
</template>

<script>
import AuthService from "@/services/auth/AuthService";
import ButtonComponent from "../template/button-component.vue";
import InputComponent from "../template/input-component.vue";

export default {
  components: {
    InputComponent,
    ButtonComponent,
  },
  emits: [
    'forgotPassword'
  ],
  data() {
    return {
      email: "",
      password: "",
      error: false,
    };
  },
  methods: {
    async onSubmit() {
      const response = await AuthService.login(this.email, this.password);

      if (response) {
        const auth = await AuthService.signIn(this.email, this.password);
        localStorage.setItem("auth", this.email);
        await this.$store.dispatch("user/loadProfile", this.email);
        this.$router.push("/");

        return;
      }

      this.error = true;
    },
  },
};
</script>

<style lang="scss">
.form {
  > .input {
    margin-bottom: 2rem;
    width: 60%;
    display: block;

    @media (max-width: 800px) {
      width: 80%;
    }
  }

  > .login-error {
    width: 100%;
    color: red;
  }

  > .button {
    width: 41%;
    margin-top: 4rem;

    @media (max-width: 800px) {
      width: 60%;
      margin: auto;
    }
  }

  .forgot-password {
    width: 100%;
    margin-top: 3rem;
    color: $blue;
    font-weight: 500;
    cursor: pointer;
  }
}
</style>
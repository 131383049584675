<template>
  <section class="user-manual-edit">
    <alert-modal v-if="showMessage" text="Dados atualizados com sucesso !" />
    <div class="top-button">
      <button-component
        text="Cadastrar colaborador"
        class="button"
        @click="cleanForm"
      />
    </div>
    <section class="form">
      <div class="col">
        <section
          v-for="(field, index) in form.firstFields"
          :key="index"
          class="field-container"
        >
          <input-component
            :label="field.label"
            v-model="field.model"
            :name="field.label"
            template="semi-transparent"
            class="field"
            :type="field.type || 'text'"
            :mask="field.mask"
            :errorCondition="
              field.model.length > 0 && !field.validation(field.model)
            "
          />
        </section>
      </div>
      <div class="col">
        <section
          v-for="(field, index) in form.secondFields"
          :key="index"
          class="field-container"
        >
          <input-component
            :label="field.label"
            v-model="field.model"
            :name="field.label"
            template="semi-transparent"
            class="field"
            :mask="field.mask"
            :type="field.type || 'text'"
            :errorCondition="
              field.model.length > 0 && !field.validation(field.model)
            "
          />
        </section>
      </div>
      <div class="save-button">
        <button-component
          text="Salvar"
          class="button"
          @click="createCollaborator"
        />
      </div>
    </section>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ButtonComponent from "../template/button-component.vue";
import InputComponent from "../template/input-component.vue";
import CollaboratorService from "@/services/CollaboratorService";
import AlertModal from "@/components/template/alert-modal.vue";

export default {
  components: {
    ButtonComponent,
    InputComponent,
    AlertModal,
  },
  data() {
    return {
      showMessage: false,
    };
  },
  computed: {
    ...mapState("collaborator", ["form"]),
  },
  methods: {
    ...mapMutations("collaborator", ["cleanForm"]),

    async createCollaborator() {
      const collaborator = {
        cpf: this.form.firstFields[3].model
          .replace(/\./g, "")
          .replace(/\-/g, ""),
          
        nomeCompleto: this.form.firstFields[1].model,
        dataNascimento: this.form.firstFields[2].model.split('/').reverse().join('-'),
        email: this.form.firstFields[5].model,
        telefone: this.form.firstFields[4].model.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""),
        endereco: this.form.secondFields[5].model,
        // escolaridade: this.form.firstFields[4].model,
        rendaMensal: this.form.secondFields[3].model,
        cnpjEmpregador: this.form.firstFields[0].model,
        matricula: this.form.firstFields[6].model,
        cargo: this.form.firstFields[7].model,
        margemTotal: this.form.firstFields[8].model,
        bancosEmprestimosAtivos: this.form.secondFields[0].model,
        descontosEmprestimos: this.form.secondFields[1].model,
        margemParcial: this.form.secondFields[2].model,
        dataAdmissao: this.form.secondFields[4].model.split('/').reverse().join('-'),
        cep: this.form.secondFields[6].model,
        estado: this.form.secondFields[5].model,
      };

      const response = await CollaboratorService.createCollaborator(
        collaborator
      );

      if (response) {
        this.cleanForm();
        this.$store.dispatch("collaborator/loadCollaborators");
        this.showMessage = true;

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss">
.user-manual-edit {
  > .top-button {
    margin-top: 2rem;

    > .button {
      width: 30%;

      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }

  > .form {
    padding: 1rem;
    margin-top: 1rem;
    background-color: $lightgrey;
    border-radius: 15px;
    width: 100%;
    min-height: 20vh;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media (max-width: 800px) {
      padding: 0;
      padding-bottom: 2rem;
    }

    > .col {
      width: 45%;
      margin: 0.5rem;

      &:nth-of-type(2) {
        @media (max-width: 800px) {
          margin-top: 1rem;
        }
      }

      @media (max-width: 800px) {
        width: 100%;
      }

      > .field-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        > .field {
          width: 100%;
        }
      }
    }

    > .save-button {
      width: 100%;
      position: relative;

      > .button {
        width: 10%;
        height: 30px;
        position: absolute;
        bottom: -1;
        right: 0;

        @media (max-width: 800px) {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>

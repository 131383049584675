<template>
  <table class="user-import-search-list">
    <line-viewer v-show="showModal" :text="linesToSee" @close="closeModal" />
    <tr class="header">
      <th v-for="(header, index) in headers" :key="index">
        {{ header }}
      </th>
    </tr>
    <tr v-for="item in imports" :key="item.cpf" class="body-line">
      <td>
        {{ item.id }}
      </td>
      <td>
        {{ item.nomeArquivo }}
      </td>
      <td>
        {{ item.userImport }}
      </td>
      <td
        class="status"
        :class="{
          success: item.status === 'CONCLUÍDO',
          warning: item.status === 'INCOMPLETO',
          danger: item.status === 'INVÁLIDO',
        }"
      >
        {{ item.status }}
      </td>
      <td class="seeLines" @click="openModal(item)">
        Ver linhas
      </td>
      <td>
        {{ item.data }}
      </td>
      <!-- <td>
        <button-component text="Reenviar" class="button --edit" />
      </td> -->
    </tr>
  </table>
</template>

<script>
import { mapState } from "vuex";
import ButtonComponent from "../../template/button-component.vue";
import LineViewer from "../../line-viewer.vue";

export default {
  components: {
    ButtonComponent,
    LineViewer,
  },
  data() {
    return {
      headers: [
        "Código",
        "Arquivo",
        "Responsável",
        "Status",
        "Linhas com erros",
        "Data",
      ],
      showModal: false,
      linesToSee: "",
    };
  },
  computed: {
    ...mapState("user-import", ["imports"]),
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    openModal(item) {
      this.linesToSee = item.linhasErro;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.user-import-search-list {
  width: 100%;
  font-size: 12px;

  > .header {
    text-align: left;

    > th {
      margin-bottom: 0.5rem;
    }
  }

  > .body-line {
    > td {
      padding: 0.5rem 0;
    }

    > .status {
      text-transform: uppercase;
      font-weight: bold;

      &.success {
        color: green;
      }

      &.warning {
        color: $orange;
      }

      &.danger {
        color: red;
      }
    }

    > .seeLines {
      text-decoration: underline;
      cursor: pointer;
      color: $blue;
    }

    .button {
      width: 80px;
      font-size: 11px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.--edit {
        background-color: $orange;
      }

      &.--delete {
        background-color: $red;
      }
    }
  }
}
</style>

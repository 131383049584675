export default [
  {
    icon: '/assets/icons/home-icon-left-side.png',
    url: '/',
    alt: 'Home',
  },
  {
    icon: '/assets/icons/bell-icon-left-side.png',
    url: '/notificacoes',
    alt: 'Página de notificação',
  },
];
import { createApp } from "vue";
import App from "./App.vue";
import routes from './routes/routes'
import { createRouter, createWebHistory } from 'vue-router'
import { regularIcons, solidIcons } from "./data/icons";
import * as FontAwesomeRegularIcons from '@fortawesome/free-regular-svg-icons';
import * as FontAwesomeSolidIcons from '@fortawesome/free-solid-svg-icons';
import VueTheMask from 'vue-the-mask'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import beforeEach from "./routes/beforeEach";
import store from "./store/store";

// import { faHouse } from '@fortawesome/free-solid-svg-icons';
// import {  } from '@fortawesome/free-regular-svg-icons';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(beforeEach);

regularIcons.forEach(icon => {
  library.add(FontAwesomeRegularIcons[icon]);
});

solidIcons.forEach(icon => {
  library.add(FontAwesomeSolidIcons[icon]);
});

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.use(VueTheMask);
app.use(store);
app.mount("#app");

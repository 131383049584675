<template>
  <table class="user-search-list">
    <tr class="header">
      <th v-for="(header, index) in headers" :key="index" class="header-item">
        {{ header }}
      </th>
    </tr>
    <tr
      v-for="item in errors"
      :key="item.cpf"
      class="body-line"
      :class="{
        '--success': item.status === 'Funcionando',
        '--warning': item.status === 'Instável',
        '--danger': item.status === 'Parada',
      }"
    >
      <td>
        {{ item.nome }}
      </td>
      <td>
        {{ item.status }}
      </td>
      <td>
        {{ item.localDoErro }}
      </td>
    </tr>
  </table>
</template>

<script>
import ButtonComponent from "../template/button-component.vue";

export default {
  components: {
    ButtonComponent,
  },
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: ["Nome", "Status", "Erro"],
    };
  },
};
</script>

<style lang="scss" scoped>
.user-search-list {
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;

  > .header {
    text-align: left;

    > th {
      margin-bottom: 0.5rem;
      padding: 0.5rem 0.5rem;
    }
  }

  > .body-line {
    border-radius: 15px;
    margin: 0.5rem 0;
    border-spacing: 0 1em;

    &.--danger {
      background-color: #f99;
    }

    &.--warning {
      background-color: #fce395;
    }

    > td {
      padding: 0.5rem 0.5rem;

      &:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      &:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    .button {
      width: 100px;
      font-size: 11px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.--edit {
        background-color: $orange;
      }

      &.--delete {
        background-color: $red;
      }
    }
  }
}
</style>

import CollaboratorService from "@/services/CollaboratorService";

const collaboratorsState = {
  namespaced: true,
  state: () => ({
    collaborators: {
      content: [],
      totalPages: 0,
      page: 0,
    },
    editingCollaborator: null,
    form: {
      firstFields: [
        {
          label: "CNPJ Empregador",
          model: "",
          mask: ["##.###.###/####-##"],
          validation: (text) => text.length === 18,
        },
        {
          label: "Nome",
          model: "",
          mask: [],
          validation: (text) => text.length > 2,
        },
        {
          label: "Data de Nascimento",
          model: "",
          mask: ["##/##/####"],
          validation: (text) => text.length == 10,
        },
        {
          label: "CPF",
          model: "",
          mask: ["###.###.###-##"],
          validation: (text) => text.length == 14,
        },
        {
          label: "Telefone",
          model: "",
          mask: ["(##) #####-####"],
          validation: (text) => text.length == 15,
        },
        {
          label: "Email",
          model: "",
          mask: [],
          validation: (text) => {
            return text
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
          },
        },
        {
          label: "Matrícula",
          model: "",
          mask: [],
          validation: (text) => text.length > 0,
        },
        {
          label: "Cargo",
          model: "",
          mask: [],
          validation: (text) => text.length > 0,
        },
        {
          label: "Margem total",
          model: "",
          mask: [],
          type: 'number',
          validation: (text) => text.length > 0,
        },
      ],
      secondFields: [
        {
          label: "Banco empréstimo",
          model: "",
          mask: [],
          validation: (text) => text.length > 0,
        },
        {
          label: "Desconto/Empréstimo",
          model: "",
          mask: [],
          type: 'number',
          validation: (text) => text.length > 0,
        },
        {
          label: "Margem parcial",
          model: "",
          mask: [],
          type: 'number',
          validation: (text) => text.length > 0,
        },
        {
          label: "Salário base",
          model: "",
          mask: [],
          type: 'number',
          validation: (text) => text.length > 0,
        },
        {
          label: "Data admissão",
          model: "",
          mask: ["##/##/####"],
          validation: (text) => text.length == 10,
        },
        {
          label: "Endereço",
          model: "",
          mask: [],
          validation: (text) => text.length > 0,
        },
        {
          label: "CEP",
          model: "",
          mask: ["##.###-###"],
          validation: (text) => text.length === 10,
        },
      ],
    },
  }),
  mutations: {
    cleanForm(state) {
      state.form.firstFields = state.form.firstFields.map((field) => {
        field.model = "";

        return field;
      });

      state.form.secondFields = state.form.secondFields.map((field) => {
        field.model = "";

        return field;
      });
      
      state.editingCovenant = null;
    },
    setCollaborators(state, collaborators) {
      state.collaborators = collaborators;
    },
    setEditingCollaborator(state, collaborator) {
      state.editingCollaborator = collaborator;
      state.form.firstFields[0].model = collaborator.cnpjEmpregador?.replace(/\D/g, "") || "";
      state.form.firstFields[2].model = collaborator.dataNascimento?.replace(/\D/g, "") || "";
      state.form.firstFields[1].model = collaborator.nomeCompleto || "";
      state.form.firstFields[3].model = collaborator.cpf?.replace(/\D/g, "") || "";
      state.form.firstFields[4].model = collaborator.telefone?.replace(/\D/g, "") || "";
      state.form.firstFields[5].model = collaborator.email || "";
      state.form.firstFields[6].model = collaborator.matricula || "";
      state.form.firstFields[7].model = collaborator.cargo || "";
      state.form.firstFields[8].model = collaborator.margemTotal || "";

      state.form.secondFields[0].model =
        collaborator.bancosEmprestimosAtivos || "";

      state.form.secondFields[1].model = collaborator.descontosEmprestimo || "";
      state.form.secondFields[2].model = collaborator.margemParcial || "";
      state.form.secondFields[3].model = collaborator.rendaMensal || "";
      state.form.secondFields[4].model = collaborator.dataAdmissao?.replace(/\D/g, "") || "";
      state.form.secondFields[5].model = collaborator.endereco || "";
      state.form.secondFields[6].model = collaborator.cep?.replace(/\D/g, "") || "";
    },
  },
  actions: {
    async loadCollaborators(context, data = { query: "", page: 0 }) {
      const response = await CollaboratorService.getCollaborators(
        data.query,
        data.page
      );

      let settled = {
        content: [
          {
            bancosEmprestimosAtivos: 'BV',
            cargo: 'Admissor',
            cep: '30626440',
            cnpjEmpregador: '6545545656',
            cpf: "01348488107",
            dataAdmissao: "1986-09-16",
            dataNascimento: "1986-09-16",
            descontosEmprestimo: 20,
            email: "walther1822@gmail.com",
            endereco: "Rua O 5, Sol Nascente, 78051-621, Cuiabá - MT",
            escolaridade: "Ensino Fundamental Completo",
            estado: 'MG',
            margemParcial: 323,
            margemTotal: 580,
            matricula: 2230,
            nomeCompleto: "Walter Pereira da Silva",
            rendaMensal: "1860.00",
            telefone: "65999357538",
          },
        ],
        totalPages: 0,
        page: 0,
      };

      if (response.content !== null) {
        settled = response;
      }

      settled.content = settled.content.map(collaborator => {
        collaborator.dataNascimento = collaborator.dataNascimento.split('-').reverse().join('/');
        collaborator.dataAdmissao = collaborator.dataAdmissao?.split('-').reverse().join('/');

        return collaborator;
      })

      context.commit("setCollaborators", settled);
    },
  },
};

export default collaboratorsState;

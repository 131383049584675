<template>
  <section class="login-view">
    <login-container />
  </section>
</template>

<script>
import loginContainer from '@/components/login/login-container.vue'

export default {
  name: 'login-view',
  components: {
    loginContainer
  }
}
</script>

<style lang="scss" scoped>
  .login-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: url('@/assets/images/full-background.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width:800px) {
      align-items: flex-start;
      background: none;
    }
  }
</style>
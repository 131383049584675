<template>
  <section class="service-control">
    <div class="fgts-container">
      <span class="title"> FGTS </span>
      <div class="banks-list">
        <checkbox-component
          v-for="(bank, index) in form.services.fgts"
          :key="index"
          :label="bank.name"
          noSelectedColor="white"
          class="bank-item"
          v-model="bank.active"
          border="1px solid black"
        />
      </div>
    </div>
    <div class="credit-container">
      <span class="title"> Crédito Privado </span>
      <div class="banks-list">
        <checkbox-component
          v-for="(bank, index) in form.services.privateCredit"
          :key="index"
          :label="bank.name"
          noSelectedColor="white"
          border="1px solid black"
          class="bank-item"
          v-model="bank.active"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import CheckboxComponent from "../template/checkbox-component.vue";

export default {
  components: {
    CheckboxComponent,
  },
  computed: {
    ...mapState('covenant', ['form']),
  },
  methods: {
    ...mapMutations('covenant', ['setServiceActive']),
  }
};
</script>

<style lang="scss" scoped>
.service-control {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 1rem;

  > * {
    width: 35%;
    background-color: $lightgrey;
    height: 85%;
    border-radius: 15px;
    padding: 1rem 1rem 1.1rem 1rem;
    font-size: 12px;

    > .title {
      font-weight: bold;
    }

    > .banks-list {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;
      margin-top: 0.5rem;

      > .bank-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        font-size: 11px;
        padding: 2px 0;
      }
    }
  }

  > .fgts-container {
  }
}
</style>

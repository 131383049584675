<template>
  <section class="user-create-container">
    <button-component class="button" text="Criar usuário" @click="cleanForm"/>
    <div class="create-form">
      <div v-for="(field, key) in form.fields" :key="key" class="field">
        <input-component
          v-model="field.model"
          :id="field.id"
          class="field"
          template="semi-transparent"
          :label="field.label"
          :type="field.type"
        />
      </div>
      <div class="reset-container">
        <!-- <button-component text="Resetar senha" class="button"/> -->
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ButtonComponent from "../template/button-component.vue";
import InputComponent from "../template/input-component.vue";

export default {
  components: {
    ButtonComponent,
    InputComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('user', ['form'])
  },
  methods: {
    ...mapMutations('user', ['cleanForm'])
  }
};
</script>

<style lang="scss" scoped>
.user-create-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }

  > .button {
    width: 20%;
    font-size: 12px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  > .create-form {
    background-color: $lightgrey;
    width: 70%;
    border-radius: 15px;
    padding: 1rem;
    position: relative;

    @media (max-width: 800px) {
      width: 85%;
    }

    > .field {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: space-evenly;
      font-size: 12px;

      > .label {
        color: black;
        margin-right: 10px;
        width: 10%;
        text-align: right;
      }

      > .field {
        width: 70%;
      }
    }

    > .reset-container {
      width: 100%;
      margin-top: 2rem;
  
      > .button {
        width: 30%;
        height: 30px;

        @media (max-width: 800px) {
          width: 60%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    > .save-button {
      width: 30%;
      height: 30px;
      position: absolute;
      bottom: -2;
      right: 0;
      margin-right: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 800px) {
        margin-right: 1rem;
      }
    }
  }
}
</style>

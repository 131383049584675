<template>
  <section class="covenant-search">
    <div class="header">
      <button-component
        class="button"
        text="Pesquisar convênio"
        @click="tryToSearch"
      />
      <div class="input-container">
        <input-component
          template="transparent"
          class="search-input"
          :isImage="false"
          :icon="['fas', 'magnifying-glass']"
          :iconBegin="false"
          v-model="search"
        />
      </div>
    </div>
    <div class="body">
      <covenant-search-list :covenants="covenants"/>
    </div>
  </section>
</template>

<script>
import ButtonComponent from "../../template/button-component.vue";
import InputComponent from "../../template/input-component.vue";
import CovenantSearchList from "./covenant-search-list.vue";
import { mapState } from 'vuex';

export default {
  components: {
    ButtonComponent,
    InputComponent,
    CovenantSearchList,
  },
  data() {
    return {
      search: "",
    };
  },
  mounted() {
    this.fetchCovenants();
  },
  computed: {
    ...mapState('covenant', ['covenants'])
  },
  methods: {
    async fetchCovenants() {
      await this.$store.dispatch("covenant/loadCovenants");
    },

    async tryToSearch() {
      await this.$store.dispatch("covenant/loadCovenants", this.search);
    }
  },
};
</script>

<style lang="scss" scoped>
.covenant-search {
  > .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }

    > .button {
      width: 30%;
      font-size: 12px;

      @media (max-width: 800px) {
        height: 30px;
        width: 95%;
        margin-top: 1rem;
      }
    }

    > .input-container {
      width: 60%;
      background-color: $lightgrey;
      border-radius: 15px;
      padding: 0 1rem;

      @media (max-width: 800px) {
        width: 85%;
      }

      > .search-input {
        width: 100%;
      }
    }
  }

  > .body {
    margin-top: 1rem;
  }
}
</style>

import BaseService from "../BaseService";

const AuthService = {
  login: async (email, password) => {
    const body = { email, senha: password };

    const response = await BaseService.post(
      "/management-access/api/zimpe-access-management/v1/login",
      body,
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null && response.status === 200;
  },

  signIn: async (email, password) => {
    const body = {
      email,
      username: email,
      password,
      role: ["admin"],
    };

    const response = await BaseService.post(
      "/management-access/api/zimpe-access-management/v1/api/auth/signin",
      body,
      {
        headers: { "content-type": "application/json" },
      }
    );

    return response;
  },

  signUp: async (email, password) => {
    const body = {
      email,
      username: email,
      password,
      role: ["admin"],
    };

    const response = await BaseService.post(
      "/management-access/api/zimpe-access-management/v1/api/auth/signup",
      body,
      {
        headers: { "content-type": "application/json" },
      }
    );

    return response;
  },

  forgotPassword: async (email) => {
    const body = { email };

    const response = await BaseService.post(
      "/management-access/api/zimpe-access-management/v1/send-token",
      body
    );

    return response !== null;
  },

  validateToken: async (emailToken) => {
    const response = await BaseService.post(
      "/management-access/api/zimpe-access-management/v1/validate-token",
      emailToken
    );

    return response !== null;
  },

  changePassword: async (emailPassword) => {
    const response = await BaseService.post(
      "/management-access/api/zimpe-access-management/v1/change-password",
      emailPassword
    );

    return response !== null;
  },
};

export default AuthService;

<template>
  <section class="user-import">
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">
          Importação e Atualização em massa dos colaboradores
        </div>
        <section class="management-containers">
          <div class="left-containers">
            <user-import-container />
          </div>
          <div class="right-containers">
            <user-import-search class="search"/>
          </div>
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import UserImportContainer from '@/components/user-import/user-import-container.vue';
import UserImportSearch from '@/components/user-import/user-import-search/user-import-search.vue';

export default {
  components: {
    NavBar,
    DefaultBody,
    UserImportContainer,
    UserImportSearch
  },
  mounted() {
    this.$store.dispatch('user-import/loadImports');
  }
}
</script>

<style lang="scss">
.user-import {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      > .management-containers {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > * {
          height: 100%;
        }

        > .left-containers {
          display: flex;
          justify-content: flex-start;
          width: 55%;
          margin-top: 2rem;
          padding-right: 2rem;
          flex-wrap: wrap;

          @media (max-width: 800px) {
            width: 100%;
            padding-right: 0;
          }

          > .forms {
            display: flex;
            height: 100%;

            @media (max-width: 800px) {
              flex-wrap: wrap;
            }
          }

          > .save-button {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 1rem;

            > .button {
              width: 15%;
              height: 30px;
              
              @media (max-width: 800px) {
                width: 60%;
              }
            }
          }
        }

        > .right-containers {
          width: 45%;
          padding-left: 2rem;

          @media (max-width: 800px) {
            padding-left: 0;
            width: 100%;
            margin-top: 2rem;
          }

          > .search {
            width: 100%;
            height: 65vh;
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
import BaseService from "./BaseService";

const ErrorsService = {
  getErrors: async (query = '', page = 0) => {
    const response = await BaseService.get(
      `/managementErrors/api/zimpe-management-errors/v1/errors-result?page=${page}&size=20&query=${query}`,
      {
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null ? response.data : null;
  },
};

export default ErrorsService;

<template>
  <section class="error-management-view">
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">Gerenciamento de Erros</div>
        <section class="management-containers">
          <error-management-search class="search" />
          <div class="refresh-container" @click="$store.dispatch('error/loadErrors')">
            <span class="text"> Atualizar </span>
            <img
              src="@/assets/images/reload-icon.png"
              alt="Recarregar"
              class="image"
            />
          </div>
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import ButtonComponent from "@/components/template/button-component.vue";
import ErrorManagementSearch from "@/components/error-management/error-management-search.vue";

export default {
  components: {
    NavBar,
    DefaultBody,
    ButtonComponent,
    ErrorManagementSearch,
  },
};
</script>

<style lang="scss">
.error-management-view {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      > .management-containers {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > * {
          height: 100%;
        }

        > .search {
          width: 50%;
        }

        > .refresh-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 2rem;
          color: $blue;
          cursor: pointer;

          > .image {
            width: 30px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <router-view />
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
  name: 'App',
  directives: {mask}
}
</script>

<style lang="scss">
@font-face {
  font-family: Jane;
  src: url('@/assets/fonts/Jane/JaneRegularW00Regular.ttf');
}

@font-face {
  font-family: Ealing;
  src: url('@/assets/fonts/Ealing/EalingRegular W00Regular.ttf');
}

@font-face {
  font-family: Inter;
  src: url('@/assets/fonts/Inter/Inter.ttf');
}

@font-face {
  font-family: Ubuntu;
  src: url('@/assets/fonts/Ubuntu/Ubuntu-Medium.ttf');
}

body {
  margin: 0;
}

#app {
  font-family: Ubuntu, Jane, Inter, Ealing, ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}
</style>

import BankService from "@/services/BankService";
import CovenantService from "@/services/CovenantService";

const covenantState = {
  namespaced: true,
  state: () => ({
    covenants: [],
    editingCovenant: null,
    form: {
      covenantFields: [
        {
          label: "CNPJ",
          type: "text",
          mask: ["##.###.###/####-##"],
          model: "",
          valid: false,
        },
        { label: "Razão Social", type: "text", mask: [], model: "" },
        {
          label: "Nome Fantasia",
          type: "text",
          mask: [],
          semiExpanded: true,
          model: "",
          valid: false,
        },
      ],
      contactFields: [
        { label: "Nome", type: "text", mask: [], model: "", valid: false },
        {
          label: "Telefone",
          type: "text",
          mask: ["(##) # ####-####"],
          model: "",
          valid: false,
        },
        { label: "E-mail", type: "text", mask: [], model: "", valid: false },
      ],
      bottomFields: [
        {
          label: "Quantidade de colaboradores",
          type: "number",
          mask: [],
          model: 1,
          valid: true,
        },
        { label: "Endereço", type: "text", mask: [], model: "", valid: false },
      ],
      appPossibility: "Sim",
      services: {
        fgts: [
          { name: "Neon", active: true },
          { name: "BV", active: false },
          { name: "Santander", active: false },
          { name: "Rodobens", active: false },
          { name: "Zipdin", active: false },
          { name: "Vipe/FFA", active: false },
          { name: "Bulla", active: false },
          { name: "Crefaz", active: false },
          { name: "Todos", active: false },
        ],
        privateCredit: [
          { name: "Neon", active: true },
          { name: "BV", active: false },
          { name: "Santander", active: false },
          { name: "Rodobens", active: false },
          { name: "Zipdin", active: false },
          { name: "Vipe/FFA", active: false },
          { name: "Bulla", active: false },
          { name: "Crefaz", active: false },
          { name: "Todos", active: false },
        ],
      },
    },
  }),
  mutations: {
    cleanForm(state) {
      state.form.covenantFields = state.form.covenantFields.map((field) => {
        field.model = "";

        return field;
      });

      state.form.contactFields = state.form.contactFields.map((field) => {
        field.model = "";

        return field;
      });

      state.form.bottomFields[0].model = 1;
      state.form.bottomFields[1].model = "";
      state.editingCovenant = null;
    },
    setAppPossibility(state, appPossibility) {
      state.form.appPossibility = appPossibility;
    },
    setCovenants(state, covenants) {
      state.covenants = covenants;
    },
    setEditingCovenant(state, covenant) {
      state.editingCovenant = covenant;
      state.form.covenantFields[0].model = covenant.cnpj;
      state.form.covenantFields[1].model = covenant.razaoSocial;
      state.form.covenantFields[2].model = covenant.nomeFantasia;

      state.form.contactFields[0].model = covenant.nome;
      state.form.contactFields[1].model = covenant.telefone.replace(/\D/g, "");
      state.form.contactFields[2].model = covenant.email;

      state.form.bottomFields[0].model = covenant.quantidadeColaboradores;
      state.form.bottomFields[1].model = covenant.endereco;

      state.form.services['fgts'][0].active = false;
      state.form.services['privateCredit'][0].active = false;
      state.form.services['privateCredit'][1].active = false;

      covenant.bancos.forEach((bank) => {
        let key = 'fgts';
        let index = null;

        if (bank.tipoCredito === "FGTS") {
          index = state.form.services.fgts.findIndex(
            (service) => service.name === bank.name
          );
        }

        if (bank.tipoCredito === "privateCredit") {
          index = state.form.services.privateCredit.findIndex(
            (service) => service.name === bank.name
          );

          key = 'privateCredit';
        }

        if (index === -1) return;

        state.form.services[key][index].active = true;
      });

      state.form.appPossibility = covenant.propostaSemBase ? 'Sim' : 'Não'
    },
    setServiceActive(state, { service, index, active }) {
      state.form.services[service][index].active = active;
    },
    setFormItem(state, { fieldPosition, index, value }) {
      state.form[fieldPosition][index].model = value;
    },
    setFormItemValidation(state, { fieldPosition, index, value }) {
      state.form[fieldPosition][index].valid = value;
    },
    setServices(state, payload) {
      state.form.services = payload;
    },
  },
  actions: {
    async loadCovenants(context, search) {
      const response = await CovenantService.getCovenants(search);

      if (response && Array.isArray(response)) {
        context.commit("setCovenants", response);
      }
    },

    async loadServices(context) {
      const response = await BankService.getBanks();

      if (response) {
        const services = {
          fgts: [],
          privateCredit: [],
        };

        response.forEach((item) => {
          const key = item.tipoCredito === "FGTS" ? "fgts" : "privateCredit";

          services[key].push({
            name: item.name,
            active: false,
          });
        });

        context.commit("setServices", services);
      }
    },
  },
};

export default covenantState;

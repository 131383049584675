import axios from "axios";

const BaseService = {
  url: process.env.VUE_APP_API_URL,

  getBaseUrl() {
    return `${location.protocol}//${this.url}`;
  },

  getConfigs(preConfigs) {
    let authConfigs = preConfigs !== null ? { ...preConfigs } : {};

    if (authConfigs.headers === undefined) {
      authConfigs.headers = [];
    }

    authConfigs.headers["Authorization"] = localStorage.getItem("token");

    return authConfigs;
  },

  async post(url, body, configs) {
    const response = await axios
      .post(`${this.getBaseUrl()}${url}`, body, this.getConfigs(configs))
      .catch(() => null);

    if (response === null) {
      return null;
    }

    if (response.status && [200, 201].includes(response.status)) {
      return response;
    }

    return response.response;
  },

  async get(url, configs) {
    const response = await axios.get(
      `${this.getBaseUrl()}${url}`,
      this.getConfigs(configs)
    ).catch(() => null);

    return response;
  },
};

export default BaseService;

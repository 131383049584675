<template>
  <table class="covenant-search-list">
    <tr class="header">
      <th v-for="(header, index) in headers" :key="index">
        {{ header }}
      </th>
    </tr>
    <tr v-for="covenant in covenants" :key="covenant.cnpj" class="body-line">
      <td>
        {{ covenant.cnpj }}
      </td>
      <td>
        {{ covenant.razaoSocial }}
      </td>
      <td>
        {{ covenant.nomeFantasia }}
      </td>
      <td>
        <button-component
          text="Editar"
          class="button --edit"
          @click="setEditingCovenant(covenant)"
        />
      </td>
      <td>
        <button-component
          :text="renderButtonText(covenant)"
          class="button --edit"
          @onClick="changeUserStatus(covenant)"
          :class="{ '--able': !covenant.isActive, '--disable': covenant.isActive }"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import CovenantService from "@/services/CovenantService";
import { mapMutations } from "vuex";
import ButtonComponent from "../../template/button-component.vue";

export default {
  components: {
    ButtonComponent,
  },
  props: {
    covenants: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: ["CNPJ", "Razão Social", "Nome Fantasia", "", ""],
    };
  },
  methods: {
    ...mapMutations("covenant", ["setEditingCovenant"]),

    async disableCompany(covenant) {
      await CovenantService.disableCovenant(covenant.cnpj);
      await this.$store.dispatch("covenant/loadCovenants");
    },

    async changeUserStatus(data) {
      const covenant = {
        cnpj: data.cnpj,
        isActive: !data.isActive,
      };

      const response = await CovenantService.createCovenant(
        covenant,
        true
      );

      if (response) {
        this.$store.dispatch("covenant/loadCovenants");
        this.showMessage = true;

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },

    renderButtonText(covenant) {
      return covenant.isActive ? "Desabilitar" : "Habilitar";
    },
  },
};
</script>

<style lang="scss">
.covenant-search-list {
  width: 100%;
  font-size: 12px;

  > .header {
    text-align: left;

    > th {
      margin-bottom: 0.5rem;
    }
  }

  > .body-line {
    > td {
      padding: 0.5rem 0;
    }

    .button {
      width: 80px;
      font-size: 11px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.--edit {
        background-color: $orange;
      }

      &.--disable {
        background-color: $red;
      }

      &.--able {
        background-color: $green;
      }
    }
  }
}
</style>

import BaseService from "./BaseService";

const CovenantService = {
  createCovenant: async (company, edit = false) => {
    const path = edit ? "/edit-company" : "/register-company";

    const response = await BaseService.post(
      `/managementCompany/api/zimpe-management-company/v1${path}`,
      company,
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    if (response === null) {
      return null;
    }

    return true;
  },

  disableCovenant: async (cnpj) => {
    const response = await BaseService.post(
      `/managementCompany/api/zimpe-management-company/v1/disable-company`,
      { cnpj },
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    if (response === null) {
      return null;
    }

    return true;
  },

  getCovenants: async (filter) => {
    const basePath = "/managementCompany/api/zimpe-management-company/v1";
    const path =
      filter === undefined ? "/list-company" : `/search?query=${filter}`;

    const response = await BaseService.get(`${basePath}${path}`, {
      withCredentials: true,
      headers: { "Content-type": "application/json" },
    });

    if (response === null) {
      return [];
    }

    return response.data;
  },
};

export default CovenantService;

<template>
  <section class="covenant-forms">
    <div class="forms">
      <covenant-data-form class="data-form"/>
    </div>
  </section>
</template>

<script>
import CovenantDataForm from './covenant-data-form.vue'

export default {
  components: {
    CovenantDataForm
  }
}
</script>

<style lang="scss" scoped>
.covenant-forms {
  width: 100%;
  
  > .forms {
    display: flex;
    justify-content: space-between;
    height: 100%;

    > .data-form {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
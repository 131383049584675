<template>
  <section class="alert-modal">
    {{ text }}
  </section>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Salvo com sucesso !'
    }
  }
}
</script>

<style lang="scss">
.alert-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgreen;
  color: rgb(57, 70, 91);
  padding: 1.5rem;
  border-radius: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 100;
}
</style>
<template>
  <section class="app-possibility">
    <span> Permite que cadastre proposta via aplicativo sem base? </span>
    <div class="checks">
      <radio-component
        v-for="(item, index) in checks"
        :key="index"
        :label="item.label"
        noSelectedColor="white"
        border="1px solid black"
        v-model="form.appPossibility"
        name="possiblity"
        class="check"
        @change="reactChangeAppPossibility"
      />
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import RadioComponent from '../template/radio-component.vue';

export default {
  components: {
    RadioComponent,
  },
  data() {
    return {
      checks: [
        { label: "Sim" },
        { label: "Não" },
      ],
    };
  },
  computed: {
    ...mapState('covenant', ['form']),
  },
  methods: {
    ...mapMutations('covenant', ['setAppPossibility']),

    reactChangeAppPossibility(event) {
      this.setAppPossibility(event.target.value)
    }
  }
};
</script>

<style lang="scss" scoped>
.app-possibility {
  background-color: $lightgrey;
  border-radius: 15px;
  height: 20%;
  padding: 1rem;
  text-align: center;

  > .checks {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    
    > .check {
      width: 20%;
    }
  }
}
</style>

<template>
  <label
    class="checkbox-item"
  >
    <input type="checkbox" class="checkbox" v-model="fieldValue" @change="onChange"/>
    <span class="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    label: {
      type: String,
      default: '',
    },
    noSelectedColor: {
      type: String,
      default: '#f1f2f2',
    },
    border: {
      type: String,
      default: 'none'
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      fieldValue: false,
    };
  },
  mounted() {
    this.fieldValue = this.modelValue;
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.fieldValue);
    },
  },
  watch: {
    modelValue(value) {
      this.fieldValue = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-item {
  font-size: 12px;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 40%;
  }

  > .checkbox {
    width: 1.3em;
    height: 1.3em;
    background-color: v-bind(noSelectedColor);
    border-radius: 50%;
    vertical-align: middle;
    border: v-bind(border);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &:checked {
      background-color: $blue;
    }
  }

  > .label {
    margin-left: 5px;
  }
}
</style>

import BaseService from "./BaseService";

const NotificationService = {
  sendNotification: async (notification) => {
    const response = await BaseService.post(
      `/managementErrors/api/zimpe-management-errors/v1/send-notification`,
      notification,
      {
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null;
  },
};

export default NotificationService;

<template>
  <section class="user-import-search">
    <!-- <div class="header">
      <button-component class="button" text="Verificar Importações" />
      <div class="input-container">
        <input-component
          template="transparent"
          class="search-input"
          :isImage="false"
          :icon="['fas', 'magnifying-glass']"
          :iconBegin="false"
        />
      </div>
    </div> -->
    <div class="body">
      <user-import-list />
    </div>
  </section>
</template>

<script>
import ButtonComponent from "../../template/button-component.vue";
import InputComponent from "../../template/input-component.vue";
import UserImportList from './user-import-list.vue';

export default {
  components: {
    ButtonComponent,
    InputComponent,
    UserImportList
  },
};
</script>

<style lang="scss" scoped>
.user-import-search {
  > .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  
    @media (max-width: 800px) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }
  
    > .button {
      width: 30%;
      font-size: 12px;
  
      @media (max-width: 800px) {
        height: 30px;
        width: 95%;
        margin-top: 1rem;
      }
    }
  
    > .input-container {
      width: 60%;
      background-color: $lightgrey;
      border-radius: 15px;
      padding: 0 1rem;
  
      @media (max-width: 800px) {
        width: 85%;
      }
  
      > .search-input {
        width: 100%;
      }
    }
  }

  > .body {
    margin-top: 1rem;
  }
}
</style>

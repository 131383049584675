import BaseService from "./BaseService";

const DeletedUserService = {
  getDeletedUsers: async (query = "", page = 0, size = 10) => {
    const cpfQuery = query === "" ? "" : `&cpf=${query}`;

    const response = await BaseService.get(
      `/managementErrors/api/zimpe-management-errors/v1/expurgo?page=${page}&size=${size}${cpfQuery}`,
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    return response === null ? null : response.data;
  },
};

export default DeletedUserService;

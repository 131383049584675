<template>
  <section class="user-container">
    <section class="header-icons" :class="{ '--full-screen': showFullIcons }">
      <div class="buguer-button" @click="setShowFullIcons">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </div>
      <section class="icons-list">
        <section
          v-for="(item, index) in icons"
          :key="index"
          class="icon-container"
          v-show="$store.state.user.profile[item.key]"
        >
          <icon-container
            :alt="item.text"
            :image="item.image"
            :url="item.path"
            v-show="showHeaderIcons"
            class="icon"
          />
        </section>
      </section>
      <section class="close-section" @click="setShowFullIconsClose">
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </section>
    </section>
    <section class="user-info-container">
      <span class="text"> Olá, {{ userName }}! </span>
      <img
        :src="'/assets/icons/user-icon.png'"
        alt="Icone de usuário"
        class="user-icon"
      />
    </section>
  </section>
</template>

<script>
import clickables from "@/data/home-clickable";
import IconContainer from "./icon-container.vue";

export default {
  components: {
    IconContainer,
  },
  data() {
    return {
      showFullIcons: false,
    };
  },
  computed: {
    icons() {
      return clickables;
    },
    showHeaderIcons() {
      return this.$route.path !== "/";
    },
    userName() {
      return `${this.$store.state.user.profile.nome}`.split(' ')[0];
    }
  },
  methods: {
    setShowFullIcons() {
      this.showFullIcons = true;
    },
    setShowFullIconsClose() {
      this.showFullIcons = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.user-container {
  color: $blue;
  display: flex;
  align-items: center;
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }

  > .header-icons {
    width: 75%;
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    transition: width 0.5s;

    &.--full-screen {
      position: absolute;
      width: 100%;
      height: 100vh;
      background-color: $lightgrey;
      z-index: 2;
      left: 0;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      > .buguer-button {
        display: none;
      }

      > .icons-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        height: 20%;

        > .icon-container {
          opacity: 1;
        }
      }

      > .close-section {
        display: flex;
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: 800px) {
      width: 10%;
    }

    > .buguer-button {
      cursor: pointer;
      display: none;

      @media (max-width: 800px) {
        display: block;
      }
    }

    > .icons-list {
      display: flex;

      @media (max-width: 800px) {
        display: none;

        > .icon-container {
          opacity: 0;
        }
      }
    }

    > .close-section {
      display: none;
    }
  }

  > .text {
    margin-right: 10px;
  }

  > .user-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .user-icon {
      cursor: pointer;
      width: 25px;
      margin-left: 10px;
    }
  }
}
</style>

import BaseService from "./BaseService";

const CollaboratorService = {
  createCollaborator: async (collaborator) => {
    const response = await BaseService.post(
      "/managementUsers/api/zimpe-management-users/v1/manual-update",
      collaborator,
      {
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null;
  },

  import: async (file, user) => {
    const response = await BaseService.post(
      `/managementUsers/api/zimpe-management-users/v1/input-users?user=${user}`,
      file,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return response !== null && response.status === 200;
  },

  getImport: async() => {
    const response = await BaseService.get(
      `/managementErrors/api/zimpe-management-errors/v1/errors-import`,
      {
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null ? response.data : []
  },

  async getCollaborators(query = "", page = 0) {
    const response = await BaseService.get(
      `/managementUsers/api/zimpe-management-users/v1/info-users?page=${page}&size=10&query=${query}`,
      {
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null ? response.data : {content: null}
  },
};

export default CollaboratorService;

import ErrorsService from "@/services/ErrorsService";

const errorState = {
  namespaced: true,
  state: () => ({
    errors: {
      content: [],
      totalPages: 0,
    },
  }),
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async loadErrors(context, data = { query: "", page: 0 }) {
      const response = await ErrorsService.getErrors(data.query, data.page);

      if (response != null) {
        context.commit("setErrors", response);

        return;
      }

      context.commit("setErrors", {
        content: [],
        totalPages: 0,
      });
    },
  },
};

export default errorState;

<template>
  <section class="user-management">
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">Atualização Manual dos Colaboradores</div>
        <section class="management-containers">
          <div class="left-containers">
            <user-manual-search-container class="search" />
          </div>
          <div class="right-containers">
            <user-manual-edit />
          </div>
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import ButtonComponent from "@/components/template/button-component.vue";
import UserManualSearchContainer from "@/components/user-manual-edit/user-manual-search-container/user-manual-search-container.vue";
import UserManualEdit from '@/components/user-manual-edit/user-manual-edit.vue';

export default {
  components: {
    NavBar,
    DefaultBody,
    UserManualEdit,
    UserManualSearchContainer,
    ButtonComponent,
  },
};
</script>

<style lang="scss" scoped>
.user-management {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      > .management-containers {
        display: flex;
        justify-content: flex-start;
        margin-top: 2rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > * {
          height: 100%;
        }

        > .left-containers {
          padding-right: 2rem;
          width: 45%;

          @media (max-width: 800px) {
            padding-right: 0;
            width: 100%;
          }

          > .search {
            margin-top: 2rem;
            height: 100%;

            @media (max-width: 800px) {
              margin-top: 3rem;
            }
          }
        }

        > .right-containers {
          padding-left: 2rem;
          width: 50%;

          @media (max-width: 800px) {
            padding-left: 0;
            width: 100%;
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
</style>

import store from "@/store/store";

const beforeEach = async (to, from) => {
  const freeRoutes = ['/login', '/esqueci-senha'];

  if (freeRoutes.includes(to.path)) {
    return true;
  }

  const jwt = localStorage.getItem('auth');

  if (jwt === null || jwt === undefined) {
    return {name: 'Login'};
  }

  if (Object.keys(store.state.user.profile).length === 0) {
    await store.dispatch('user/loadProfile', jwt);
  }

  return true;
}

export default beforeEach;
import BaseService from "./BaseService";

const UserService = {
  getInfo: async (email) => {
    const response = await BaseService.post(
      `/management-access/api/zimpe-access-management/v1/login-info`,
      { email },
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    return response === null ? null : response.data;
  },

  getUsers: async (query = "", page = 0) => {
    const response = await BaseService.get(
      `/management-access/api/zimpe-access-management/v1/list-admin?page=${page}&size=10&query=${query}`,
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    return response === null ? null : response.data;
  },

  createOrUpdate: async (user, create = true) => {
    const path = create ? 'register/admin' : 'edit-admin';

    const response = await BaseService.post(
      `/management-access/api/zimpe-access-management/v1/${path}`,
      user,
      {
        withCredentials: true,
        headers: { "Content-type": "application/json" },
      }
    );

    return response !== null;
  }
};

export default UserService;

<template>
  <div class="selectable-div">
    <select class="select-component" v-model="fieldValue" @change="onChange">
      <option v-for="(option) in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      default: () => [],
      type: Array,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      fieldValue: "",
    };
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.fieldValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.selectable-div {
  > .select-component {
    background-color: $lightgrey;
    border: none;
    border-radius: 15px;
    height: 30px;
    padding: 0.5rem;
  }

  &:after {
    margin-right: 1rem;
  }
}
</style>

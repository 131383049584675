<template>
  <section class="login-form">
    <div class="title">{{ title }}</div>
    <LoginFields
      class="form"
      @forgotPassword="changeForgotPassword"
      v-if="isLogin"
    />
    <forgot-password-fields
      @goToLogin="changeGoToLogin"
      @reset="changeGoToLogin"
      v-else
    />
  </section>
</template>

<script>
import AuthService from "@/services/auth/AuthService";
import ButtonComponent from "../template/button-component.vue";
import InputComponent from "../template/input-component.vue";
import LoginFields from "./login-fields.vue";
import ForgotPasswordFields from "./forgot-password-fields.vue";

export default {
  components: {
    InputComponent,
    ButtonComponent,
    LoginFields,
    ForgotPasswordFields,
  },
  data() {
    return {
      title: "Faça seu login",
      email: "",
      password: "",
      validateCode: "",
      error: false,
      isLogin: true,
    };
  },
  methods: {
    changeForgotPassword() {
      this.isLogin = false;
      this.title = "Esqueci minha senha";
    },
    changeGoToLogin() {
      this.isLogin = true;
      this.title = "Faça seu login";
    },
    async onSubmit() {
      const response = await AuthService.login(this.email, this.password);

      if (response) {
        const signin = await AuthService.signIn(this.email, this.password);
        localStorage.setItem("token", signin.accessToken);
        localStorage.setItem("auth", this.email);
        await this.$store.dispatch("user/loadProfile", this.email);
        this.$router.push("/");

        return;
      }

      this.error = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;

  > .title {
    font-size: 34px;
    width: 100%;
    color: $blue;
  }

  > .form {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>

<template>
  <section class="user-search-container">
    <div class="header">
      <button-component
        class="button"
        text="Pesquisar usuário"
        @click="trySearch"
      />
      <div class="input-container">
        <input-component
          template="transparent"
          class="search-input"
          :isImage="false"
          :icon="['fas', 'magnifying-glass']"
          :iconBegin="false"
          v-model="search"
        />
      </div>
    </div>
    <div class="body">
      <user-search-list />
    </div>
    <div class="footer">
      <template v-if="page > 4">
        <span class="page-list" @click="changePage(1)">
          {{ 1 }}
        </span>
        ...
      </template>
      <span
        class="page-list"
        v-for="i in maxPageList"
        :key="i"
        :class="{ '--active': i === page }"
        @click="changePage(i)"
      >
        {{ i }}
      </span>
      <template v-if="breakPage !== null">
        ...
        <span class="page-list" @click="changePage(breakPage)">
          {{ breakPage }}
        </span>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import ButtonComponent from "../../template/button-component.vue";
import InputComponent from "../../template/input-component.vue";
import UserSearchList from "./user-manual-search-list.vue";

export default {
  components: {
    ButtonComponent,
    InputComponent,
    UserSearchList,
  },
  data() {
    return {
      maxPageList: [],
      breakPage: null,
      page: 1,
      search: "",
    };
  },
  mounted() {
    this.$store.dispatch("collaborator/loadCollaborators");
    this.relogicPages(this.totalPages);
  },
  computed: {
    ...mapState("collaborator", ["collaborators"]),

    totalPages() {
      return this.collaborators.totalPages;
    },
  },
  methods: {
    trySearch() {
      this.$store.dispatch("collaborator/loadCollaborators", {
        query: this.search,
        page: this.page - 1,
      });
    },
    changePage(index) {
      this.page = index;

      this.$store.dispatch("collaborator/loadCollaborators", {
        query: this.search,
        page: this.page - 1,
      });

      if (index === 1) {
        this.relogicPages(this.totalPages);
      }

      if (index === this.totalPages) {
        this.relogicPages(this.totalPages, index - 4);

        return;
      }

      if (index === this.maxPageList.slice(-1)[0]) {
        this.relogicPages(this.totalPages, index - 1);

        return;
      }

      if (index === this.maxPageList[0]) {
        const count = index - 4 > 0 ? index - 4 : 0;

        this.relogicPages(this.totalPages, count);
      }
    },
    relogicPages(maxValue, minValue = 0) {
      const arr = [];

      for (let i = minValue; i < maxValue; i++) {
        if (i < 0) {
          continue;
        }

        arr.push(i + 1);

        if (i === minValue + 4) {
          break;
        }
      }

      if (maxValue > minValue + 4) {
        this.breakPage = maxValue;
      } else {
        this.breakPage = null;
      }

      this.maxPageList = arr;
    },
  },
  watch: {
    totalPages(value) {
      this.relogicPages(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-search-container {
  display: flex;
  flex-wrap: wrap;

  > .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }

    > .button {
      width: 20%;
      font-size: 12px;

      @media (max-width: 800px) {
        height: 30px;
        width: 95%;
        margin-top: 1rem;
      }
    }

    > .input-container {
      width: 70%;
      background-color: $lightgrey;
      border-radius: 15px;
      padding: 0 1rem;

      @media (max-width: 800px) {
        width: 85%;
      }

      > .search-input {
        width: 100%;
      }
    }
  }

  > .body {
    margin-top: 1.5rem;
    width: 100%;
    height: 45vh;
    overflow: auto;
  }

  > .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 5px;

    > .page-list {
      border: 1px solid grey;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 0 3px;
      cursor: pointer;

      &.--active {
        background-color: $blue;
        color: white;
      }
    }
  }
}
</style>

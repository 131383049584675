<template>
  <section class="nav-bar">
    <div class="items-container">
      <div
        v-for="(item, index) in items"
        :key="item.route"
        class="nav-item"
        v-show="index != 1 || $store.state.user.profile.gestaoNotificacoes"
      >
        <img
          :alt="item.alt"
          class="nav-icon"
          :src="item.icon"
          @click="redirect(item.url)"
        />
      </div>
    </div>
    <div class="config-container">
      <button-component
        text="Sair"
        :borderRadius="5"
        color="#ee5a5a"
        @click="logout"
      />
    </div>
  </section>
</template>

<script>
import navbarItems from "@/data/navbar-items";
import ButtonComponent from "./button-component.vue";

export default {
  components: {
    ButtonComponent,
  },
  computed: {
    items() {
      return navbarItems;
    },
  },
  methods: {
    redirect(url) {
      this.$router.push(url);
    },
    logout() {
      localStorage.removeItem("auth");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 5%;

  @media (max-width: 800px) {
    width: 20%;
  }

  > .items-container {
    height: 15%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;

    > .nav-item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  > .config-container {
    margin-bottom: 2rem;
  }

  .nav-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: block;
  }
}
</style>

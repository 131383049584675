<template>
  <section class="user-import-container">
    <alert-modal v-if="showMessage" />
    <div class="top-button">
      <!-- <button-component text="Importar planilha" class="button" /> -->
    </div>
    <div class="import-description">
      <img src="@/assets/images/info-icon.png" alt="Informação" class="img" />
      <span class="subtitle">
        Para melhor associação entre os conteúdos do lote, faça o dowload da
        nossa planilha abaixo e insira nela os dados correspondentes.
      </span>
    </div>
    <div class="import-container">
      <div class="info">
        <img src="@/assets/images/excel-icon.png" alt="Planilha" class="img" />
        <span class="text"> Baixe nosso modelo de planilha </span>
      </div>
      <div class="button-example">
        <button-component
          text="BAIXAR"
          class="button"
          @click="downloadExample"
        />
      </div>
      <div class="send-import">
        <div class="form">
          <button-component
            text="Escolher arquivo"
            class="button"
            @click="chooseFile"
          />
          <input-component
            template="semi-transparent"
            class="input"
            ref="file"
            type="file"
            @change="uploadFile"
            :label="fileLabel"
            id="file"
          />
        </div>
      </div>
      <div class="clean-container">
        <div class="clean-items">
          <!-- <div>Higienizar zimpe?</div>
          <div class="radios">
            <radio-component
              v-for="(item, index) in cleanOptions"
              :key="index"
              :label="item.label"
              v-model="clean"
              name="clean"
              border="1px solid black"
            /> -->
          <!-- </div> -->
          <div class="button-container">
            <button-component text="Enviar" @click="reactImport" />
          </div>
        </div>
        <!-- <div class="info-container">
          <div class="title">
            Dados da Importação
          </div>
          <div class="info">
            Nome: <span class="data"> SULBANK_203 </span>
          </div>
          <div class="info">
            Contatos importados: <span class="data"> 4.203 </span>
          </div>
          <div class="info">
            Erros: <span class="error-info data"> 0 </span>
          </div>
        </div> -->
      </div>
      <div class="error">
        {{ error }}
      </div>
    </div>
  </section>
</template>

<script>
import ButtonComponent from "@/components/template/button-component.vue";
import InputComponent from "../template/input-component.vue";
import RadioComponent from "../template/radio-component.vue";
import CollaboratorService from "@/services/CollaboratorService";
import AlertModal from "@/components/template/alert-modal.vue";
import { mapState } from 'vuex';

export default {
  components: {
    ButtonComponent,
    InputComponent,
    RadioComponent,
    AlertModal,
  },
  data() {
    return {
      clean: "Sim",
      file: null,
      cleanOptions: [
        {
          label: "Sim",
          model: true,
        },
        {
          label: "Não",
          model: false,
        },
      ],
      fileLabel: "Nenhum arquivo escolhido",
      showMessage: false,
      error: "",
    };
  },
  computed: {
    ...mapState('user', ['profile'])
  },
  methods: {
    chooseFile() {
      this.$refs.file.$refs.input.click();
    },
    downloadExample() {
      window.open("/PlanilhaExemplo.csv");
    },
    async reactImport() {
      const formData = new FormData();
      formData.append("file", this.file);
      const response = await CollaboratorService.import(formData, this.profile.nome);

      if (response) {
        this.error = "";
        this.showMessage = true;
        this.$store.dispatch('user-import/loadImports');

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);

        return;
      }

      this.error = "Não foi possível importar os dados da planilha.";
    },
    uploadFile(evt) {
      this.file = evt.files[0];
      this.fileLabel = this.file.name;
    },
  },
};
</script>

<style lang="scss">
.user-import-container {
  width: 100%;

  > .top-button {
    width: 20%;

    @media (max-width: 800px) {
      width: 100%;
    }
  }

  > .import-description {
    display: flex;
    margin-top: 2rem;

    > .img {
      width: 30px;
      height: 30px;
    }

    > .subtitle {
      margin-left: 1rem;
      font-size: 14px;
      width: 70%;
    }
  }

  > .import-container {
    margin-top: 2rem;

    > .info {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      > .img {
        width: 30px;
        height: 30px;
      }

      > .text {
        margin-left: 1rem;
        padding-top: 4px;
        font-size: 14px;
      }
    }

    > .button-example {
      width: 100%;

      > .button {
        margin-top: 0.5rem;
        width: 10%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #34ab2b;

        @media (max-width: 800px) {
          width: 100%;
        }
      }
    }

    > .send-import {
      margin-top: 2rem;
      background-color: $lightgrey;
      border-radius: 15px;
      padding: 2rem;

      @media (max-width: 800px) {
        text-align: center;
      }

      > .form {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > .button {
          width: 20%;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;

          @media (max-width: 800px) {
            width: 100%;
          }
        }

        > .input {
          margin-left: 1rem;
          width: 70%;

          @media (max-width: 800px) {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }

    > .error {
      display: flex;
      justify-content: center;
      color: red;
      text-align: center;
    }

    > .clean-container {
      display: flex;
      width: 100%;
      margin-top: 1.5rem;
      justify-content: space-between;

      @media (max-width: 800px) {
        flex-wrap: wrap;
        text-align: center;
      }

      > .clean-items {
        width: 60%;
        margin-top: 1rem;

        @media (max-width: 800px) {
          width: 100%;
        }

        > .radios {
          display: flex;
          width: 20%;

          @media (max-width: 800px) {
            width: 100%;
          }
        }

        > .button-container {
          width: 40%;
          margin-top: 1rem;

          @media (max-width: 800px) {
            width: 100%;
          }

          > .button {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        > .inform {
          font-size: 12px;
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
      }

      > .info-container {
        border: 1px solid grey;
        border-radius: 15px;
        padding: 1rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        @media (max-width: 800px) {
          width: 100%;
        }

        > .title {
          font-size: 16px;
          color: $blue;
          margin-bottom: 1rem;
        }

        > .info {
          font-size: 12px;
          padding: 0.5rem 0;

          > .data {
            font-weight: bold;

            &.error-info {
              color: #34ab2b;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <section class="login-container">
    <div class="login-info">
      <img
        src="@/assets/images/zimpe-logo.png"
        alt="Logo zimpe"
        class="logo-zimpe"
      />
      <div>
        <p>
          Olá! Faça login na sua conta para acessar todos os recursos e
          funcionalidades incríveis que o <strong> zimpe </strong> tem a oferecer.
        </p>
        <p>
          Sua jornada começa aqui. Insira suas credenciais ao lado e mergulhe em
          uma experiência única de produtividade e colaboração.
        </p>
      </div>
    </div>
    <div class="login-form-container">
      <img src="@/assets/images/zimpe-logo-1.png" class="logo-zimpe-login" alt="Zimpe Logo">
      <login-form />
    </div>
  </section>
</template>

<script>
import LoginForm from './login-form.vue';

export default {
  name: "login-container",
  components: {
    LoginForm
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  width: 70%;
  min-height: 60%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
  background-color: white;

  @media (max-width:800px) {
    width: 100%;
    box-shadow: none;
  }

  > .login-info {
    width: 40%;
    background-color: $yellow;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem;
    font-size: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    color: $blue;
    text-align: center;
    font-weight: 500;

    @media (max-width:800px) {
      display: none;
    }

    > .logo-zimpe {
      width: 250px;
      height: 100px;
    }
  }

  > .login-form-container {
    width: 60%;
    padding: 4.5rem 3rem 3rem 3rem;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > .logo-zimpe-login {
      width: 400px;

      @media (min-width:800px) {
        display: none;
      }
    }

    @media (max-width:800px) {
      width: 100%;
      padding: 0;
    }
  }
}
</style>

<template>
  <button
    class="button"
    @click="onClick"
    :class="{ '--loading': loading, color }"
    :style="{ 'border-radius': `${borderRadius}px` }"
    :disabled="disabled"
  >
    <div v-if="!loading">{{ text }}</div>
    <div v-else><loader-component /></div>
  </button>
</template>

<script>
import LoaderComponent from "./loader-component.vue";

export default {
  props: {
    text: {
      default: "Enviar",
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    borderRadius: {
      default: 20,
      type: Number,
    },
    color: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  components: {
    LoaderComponent,
  },
  emits: ["onClick"],
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
  computed: {
    backgroundColor() {
      return this.color ?? 'rgb(0, 158, 212)';
    }
  }
};
</script>

<style lang="scss" scoped>
.button {
  background-color: v-bind('backgroundColor');
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  padding: 0.5rem;
  height: 40px;
  font-size: 14px;
  font-weight: bold;

  &:disabled {
    background-color: grey;
    cursor: auto;
    transform: none;
  }

  &.--loading {
    background-color: $darkblue;
    cursor: default;
  }

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
}
</style>

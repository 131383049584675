<template>
  <table class="deleted-user-search-list">
    <tr class="header">
      <th v-for="(header, index) in headers" :key="index">
        {{ header }}
      </th>
    </tr>
    <tr v-for="user in users" :key="user.cpf" class="body-line">
      <td>
        {{ user.cpf }}
      </td>
      <td>
        {{ user.nomeCompleto }}
      </td>
      <td>
        {{ user.email }}
      </td>
      <td>
        <span class="see-more" @click="$emit('showViewer', user)">
          Visualizar mais dados
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import ButtonComponent from "../../template/button-component.vue";

export default {
  components: {
    ButtonComponent,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: ["CPF", "Nome", "E-mail", ""],
    };
  },
  methods: {
    
  },
};
</script>

<style lang="scss">
.deleted-user-search-list {
  width: 100%;
  font-size: 12px;

  > .header {
    text-align: left;

    > th {
      margin-bottom: 0.5rem;
    }
  }

  > .body-line {
    > td {
      padding: 0.5rem 0;

      > .see-more {
        color: $blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .button {
      width: 80px;
      font-size: 11px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.--edit {
        background-color: $orange;
      }

      &.--disable {
        background-color: $red;
      }

      &.--able {
        background-color: $green;
      }
    }
  }
}
</style>

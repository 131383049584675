<template>
  <section class="default-body-header">
    <breadcrumb class="breadcrumb" />
    <user-container class="user-container"/>
  </section>
</template>

<script>
import Breadcrumb from './breadcrumb.vue';
import UserContainer from './user-container.vue';

export default {
  components: {
    Breadcrumb,
    UserContainer
  }
}
</script>

<style lang="scss" scoped>
  .default-body-header {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
  }
</style>
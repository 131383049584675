<template>
  <table class="user-search-list">
    <tr class="header">
      <th v-for="(header, index) in headers" :key="index">
        {{ header }}
      </th>
    </tr>
    <tr v-for="item in users" :key="item.cpf" class="body-line">
      <td>
        {{ item.nome }}
      </td>
      <td>
        {{ item.email }}
      </td>
      <td>
        {{ item.cpf }}
      </td>
      <td>
        <button-component
          text="Editar"
          class="button --edit"
          @click="edit(item)"
        />
      </td>
      <td>
        <button-component
          :text="renderButtonText(item)"
          class="button --edit"
          @onClick="changeUserStatus(item)"
          :class="{ '--able': !item.isActive, '--disable': item.isActive }"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import UserService from "@/services/UserService";
import ButtonComponent from "../../template/button-component.vue";

export default {
  components: {
    ButtonComponent,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: ["Nome", "E-mail", "CPF", "", ""],
    };
  },
  methods: {
    async changeUserStatus(data) {
      const user = {
        email: data.email,
        isActive: !data.isActive,
      };

      const response = await UserService.createOrUpdate(user, false);

      if (response) {
        this.$store.dispatch("user/loadUsers");
        this.showMessage = true;

        setTimeout(() => {
          this.showMessage = false;
        }, 2000);
      }
    },

    edit(user) {
      this.$store.dispatch("user/loadUserEditing", user);
    },

    renderButtonText(user) {
      return user.isActive ? "Desabilitar" : "Habilitar";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-search-list {
  width: 100%;
  font-size: 12px;

  > .header {
    text-align: left;

    > th {
      margin-bottom: 0.5rem;
    }
  }

  > .body-line {
    > td {
      padding: 0.5rem 0;
    }

    .button {
      width: 100px;
      font-size: 11px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.--edit {
        background-color: $orange;
      }

      &.--disable {
        background-color: $red;
      }

      &.--able {
        background-color: $green;
      }
    }
  }
}
</style>

<template>
  <section class="breadcrumb">
    <span class="item">
      zimpe app > {{ path }}
    </span>
  </section>
</template>

<script>
export default {
  computed: {
    path() {
      let path = 'Dashboard';

      if (this.$route.path === '/') {
        return path;
      }

      return `${path} > ${this.$route.name}`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumb {
    display: flex;

    > .item {
      text-decoration: underline;

      @media (max-width: 800px) {
        display: none;
      }
    }
  }
</style>
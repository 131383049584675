<template>
  <section class="home-view">
    <nav-bar />
    <default-body class="body">
      <section class="home-body">
        <home-linkable
          v-for="(item, index) in homeClickables"
          :key="index"
          :text="item.text"
          :image="item.image"
          :url="item.path"
          class="linkable"
          v-show="$store.state.user.profile[item.key]"
        />
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import HomeLinkable from "@/components/home/home-linkable.vue";
import clickables from "@/data/home-clickable";

export default {
  name: "HomeView",
  components: {
    NavBar,
    DefaultBody,
    HomeLinkable,
  },
  computed: {
    homeClickables() {
      return clickables;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-view {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;

    > .home-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > .linkable {
        width: 20%;
        margin: 3rem 1rem;

        @media (max-width: 800px) {
          width: 100%;
          margin: 0 2rem;
        }
      }
    }
  }
}
</style>

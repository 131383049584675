<template>
  <section class="line-viewer">
    <div class="body">
      <div class="close-icon" @click="$emit('close')">x</div>
      <div class="content" v-html="text"></div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    text: {
      default: "",
      type: String,
    },
  },
  emits: ["close"],
};
</script>

<style lang="scss">
.line-viewer {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 100;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 50vh;
  overflow: auto;

  > .body {
    position: relative;
    display: flex;
    justify-content: center;
    color: black;

    > .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0.5rem;
      cursor: pointer;
    }

    > .content {
      width: 100%;
      margin: 1rem;
    }
  }
}
</style>

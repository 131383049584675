<template>
  <section class="user-screen-permission">
    <div class="title">Permissão de telas</div>
    <div class="filters">
      <span class="label">Filtrar</span>
      <div class="searcher-container">
        <input-component
          template="transparent"
          class="input"
          v-model="filterText"
        />
      </div>
      <select-component
        :options="filterOptions"
        class="searcher-filter"
        v-model="selectedFilter"
      />
    </div>
    <div class="permission-list">
      <checkbox-component
        class="permission-item"
        v-for="permission in renderOptions"
        :key="permission.name"
        :label="permission.name"
        v-model="permission.active"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import CheckboxComponent from "../template/checkbox-component.vue";
import InputComponent from "../template/input-component.vue";
import SelectComponent from "../template/select-component.vue";

export default {
  components: {
    CheckboxComponent,
    InputComponent,
    SelectComponent,
  },
  data() {
    return {
      filterText: "",
      selectedFilter: "Todos",
      filterOptions: ["Todos", "Selecionados", "Não selecionados"],
    };
  },
  computed: {
    ...mapState('user', ['form']),

    renderOptions() {
      const selectedFilter = this.form.permissions.filter((permission) => {
        if (this.selectedFilter === "Selecionados") {
          return permission.active;
        }

        if (this.selectedFilter === "Não selecionados") {
          return !permission.active;
        }

        return true;
      });

      return selectedFilter.filter((permission) => {
        return permission.name
          .toLowerCase()
          .includes(this.filterText.toLocaleLowerCase());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-screen-permission {
  margin-top: 2rem;

  > .title {
    color: $blue;
    font-weight: bold;
    font-size: 18px;

    @media (max-width: 800px) {
      display: none;
    }
  }

  > .filters {
    display: flex;
    margin-top: 1.5rem;
    align-items: center;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    > .label {
      color: $blue;
      font-size: 14px;
      font-weight: bold;
    }

    > .searcher-container {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      width: 30%;
      background-color: $lightgrey;
      border-radius: 15px;
      padding: 0 1rem;
      height: 30px;

      @media (max-width: 800px) {
        width: 100%;
        margin: 0.5rem 0;
      }

      > .input {
        width: 100%;
      }
    }

    > .searcher-filter {
      margin-left: 1rem;

      @media (max-width: 800px) {
        width: 100%;
        margin-left: 0;

        > .select-component {
          width: 100%;
        }
      }
    }
  }

  > .permission-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;

    @media (max-width: 800px) {
      justify-content: space-evenly;
    }

    > .permission-item {
      width: 30%;
    }
  }
}
</style>

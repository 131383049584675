<template>
  <section class="home-linkable" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @click="redirect">
    <div class="icon">
      <object :data="renderImage" type="image/svg+xml" />
    </div>
    <div class="body">
      <img :src="renderImage" class="icon" />
      <span class="text"> {{ text }} </span>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    image: {
      default: "/assets/icons/bell-icon-left-side.png",
      type: String,
    },
    text: {
      default: "Gestão de usuários",
      type: String,
    },
    url: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      mouseOn: false,
    };
  },
  computed: {
    renderImage() {
      return this.mouseOn ? `${this.image.replace('.svg', '')}-01.svg` : this.image
    }
  },
  methods: {
    onMouseEnter() {
      this.mouseOn = true;
    },
    onMouseLeave() {
      this.mouseOn = false;
    },
    redirect() {
      this.$router.push(this.url)
    }
  }
};
</script>

<style lang="scss" scoped>
.home-linkable {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  background-color: transparent;
  width: 15%;
  min-height: 20%;
  position: relative;
  cursor: pointer;
  height: 200px;

  @media (max-width: 800px) {
    height: 80px;
    padding-bottom: 2rem;
    width: 100%;
  }

  svg {
    display: none;
  }

  &:hover {
    > * {
      background-color: $blue !important;
      color: white;
    }
  }

  > .icon {
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
    bottom: 50%;
    transition: 0.4s;
    margin-top: 2%;
    cursor: pointer;

    @media (max-width: 800px) {
      display: none;
    }

    > .image {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  }

  > .body {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    transition: 0.4s;
    margin-top: 2%;
    padding: 0 1rem;

    @media (max-width: 800px) {
      font-size: 11px;
      justify-content: space-evenly;
      padding: 0;
      text-align: center;
      height: 100px;
    }

    > .text {
      z-index: 1;
      text-align: center;

      @media (max-width: 800px) {
        width: 60%;
      }
    }

    > .icon {
      display: none;
      cursor: pointer;

      @media (max-width: 800px) {
        display: block;
        width: 50px;
      }
    }
  }
}
</style>

<template>
  <section class="notification-selector">
    <div class="title">
      <span>Público</span>
      <span class="subtitle">
        Selecione o público para receber a notificação
      </span>
    </div>
    <div class="radios">
      <radio-component
        v-for="(radio, index) in radios"
        :key="index"
        :label="radio.label"
        v-model="way"
        border="1px solid black"
        noSelectedColor="white"
        class="radio"
      />
    </div>
    <div class="selects">
      <template v-if="selectedWay === 'Por convênio'">
        <checkbox-component
          v-for="(covenant, index) in covenants"
          :key="index"
          v-model="covenantsBoolean[index]"
          :label="covenant.nomeFantasia"
          border="1px solid black"
          noSelectedColor="white"
          class="select"
        />
      </template>
      <template v-if="selectedWay === 'Por CPF individual'">
        <input-component
          label="Digite o CPF"
          template="semi-transparent"
          :mask="['###.###.###-##']"
          class="input"
          v-model="cpf"
          @change="setCPF"
        />
      </template>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import CheckboxComponent from "../template/checkbox-component.vue";
import InputComponent from "../template/input-component.vue";
import RadioComponent from "../template/radio-component.vue";

export default {
  components: {
    CheckboxComponent,
    InputComponent,
    RadioComponent,
  },
  data() {
    return {
      way: "Por convênio",
    };
  },
  mounted() {
    this.initialLoads();
  },
  computed: {
    ...mapState("notification", [
      "selectedWay",
      "radios",
      "cpf",
      "covenantsBoolean",
    ]),

    ...mapState("covenant", ["covenants"]),
  },
  methods: {
    ...mapMutations("notification", [
      "setSelectedWay",
      "setCovenantsBooleans",
      "setCPF",
    ]),

    async initialLoads() {
      await this.$store.dispatch("covenant/loadCovenants");
      this.setCovenantsBooleans(this.covenants.map(() => false));
    },
  },
  watch: {
    way(value) {
      this.setSelectedWay(value);
    },
  },
};
</script>

<style lang="scss">
.notification-selector {
  background-color: $lightgrey;
  border-radius: 15px;
  padding: 2rem;

  > .title {
    font-size: 18px;
    font-weight: bold;
    color: black;

    > .subtitle {
      font-size: 12px;
      color: lightgrey;
      margin-left: 2rem;
    }
  }

  > .radios {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 1rem;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    > .radio {
      font-size: 14px;
      width: 35%;
      display: flex;
      align-items: center;

      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }

  > .selects {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

    > .select {
      width: 30%;
      font-size: 11px;

      @media (max-width: 800px) {
        width: 100%;
      }
    }

    > .input {
      width: 100%;
    }
  }
}
</style>

import DeletedUserService from "@/services/DeletedUserService";

const deleteUserState = {
  namespaced: true,
  state: () => ({
    deletedUsers: {
      content: [],
      totalPages: 0,
      page: 0,
    },
  }),
  mutations: {
    setDeletedUsers(state, users) {
      state.deletedUsers = users;
    },
  },
  actions: {
    async loadDeletedUsers(context, data = { cpf: "", page: 0 }) {
      const response = await DeletedUserService.getDeletedUsers(data.cpf, data.page);

      if (response != null) {
        context.commit("setDeletedUsers", response);

        return;
      }

      context.commit("setDeletedUsers", {
        content: [],
        totalPages: 0,
      });
    },
    async loadAllDeletedUsers(context, size = 10) {
      const response = await DeletedUserService.getDeletedUsers('', 0, size);

      if (response === null) {
        return [];
      }

      return response.content;
    }
  },
};

export default deleteUserState;

<template>
  <section
    class="icon-container"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    :class="{
      '--active': active,
    }"
    @click="redirect"
  >
    <img :src="renderImage" :alt="text" class="icon" />
  </section>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mouseOn: false,
    };
  },
  computed: {
    active() {
      return this.$route.path === this.url;
    },
    renderImage() {
      return this.mouseOn || this.active
        ? `${this.image.replace(".svg", "")}-01.svg`
        : this.image;
    },
  },
  methods: {
    onMouseEnter() {
      this.mouseOn = true;
    },
    onMouseLeave() {
      this.mouseOn = false;
    },
    redirect() {
      this.$router.push(this.url)
    }
  },
};
</script>

<style scoped lang="scss">
.icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  margin: 1rem;
  background-color: white;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    background-color: $blue !important;
    color: white;
  }

  &.--active {
    background-color: $blue !important;
    color: white;
  }

  > .icon {
    width: 40px;
  }
}
</style>

<template>
  <section class="deleted-user-management-view">
    <nav-bar />
    <default-body class="body">
      <section class="management-body">
        <div class="title">Gerenciamento de Colaboradores Deletados</div>
        <div class="export">
          <button-component
            class="button"
            text="Exportar usuários deletados"
            @click="importAll"
            color="#34ab2b"
          />
        </div>
        <section class="management-containers">
          <deleted-user-search />
        </section>
      </section>
    </default-body>
  </section>
</template>

<script>
import NavBar from "@/components/template/nav-bar.vue";
import DefaultBody from "@/components/template/default-body/default-body.vue";
import ButtonComponent from "@/components/template/button-component.vue";
import DeletedUserSearch from "@/components/delete-user-management/deleted-user-search/deleted-user-search.vue";
import { json2csv } from 'json-2-csv';

export default {
  components: {
    NavBar,
    DefaultBody,
    ButtonComponent,
    DeletedUserSearch,
  },
  data() {
    return {
      headers: {
        model: "Phone Model",
        chargers: "Chargers",
        cases: "Cases",
        earphones: "Earphones",
      },
    };
  },
  methods: {
    async importAll() {
      const data = await this.$store.dispatch(
        "deleted-user/loadAllDeletedUsers"
      );
      
      const csv = await json2csv(data, {
        delimiter: {field: ';'}
      });

      this.download(csv);
    },

    download(csv) {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:attachment/text,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'deletados.csv';
      hiddenElement.click();
    }
  },
};
</script>

<style lang="scss" scoped>
.deleted-user-management-view {
  background-color: $grey;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  display: flex;

  > .body {
    > .management-body {
      background-color: white;
      height: 70%;
      width: 100%;
      border-radius: 15px;
      padding: 3rem;

      @media (max-width: 800px) {
        padding: 1rem;
        width: 90%;
        height: auto;
      }

      > .title {
        font-size: 24px;
        color: $blue;
        font-weight: bold;

        @media (max-width: 800px) {
          text-align: center;
        }
      }

      > .export {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;

        > .button {
          width: 50%;
        }
      }

      > .management-containers {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        @media (max-width: 800px) {
          flex-wrap: wrap;
        }

        > * {
          height: 100%;
        }

        > .search {
          width: 50%;
        }

        > .refresh-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 2rem;
          color: $blue;
          cursor: pointer;

          > .image {
            width: 30px;
          }
        }
      }
    }
  }
}
</style>
